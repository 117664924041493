import { useMemo } from 'react'

const useAddressInputFields = (prefix = 'address', includeCounty = false, unitedStateId) => (
  useMemo(() => ([
    {
      source: `${prefix}_street_1`,
      label: 'address.street_1',
      required: true,
    },
    {
      source: `${prefix}_street_2`,
      label: 'address.street_2',
      required: false,
    },
    {
      source: `${prefix}_city`,
      label: 'address.city',
      required: true,
    },
    {
      source: `${prefix}_united_state_id`,
      label: 'address.state',
      reference: 'united_states',
      required: true,
    },
    ...(
      includeCounty ? [{
        source: `${prefix}_county_id`,
        label: 'address.county',
        reference: 'counties',
        required: false,
        disabled: !unitedStateId,
        filter: {
          united_state_id_eq: unitedStateId,
        }
      }] : []
    ),
    {
      source: `${prefix}_zip`,
      label: 'address.zip',
      required: true,
    },
  ]),[prefix, includeCounty, unitedStateId])
);

export default useAddressInputFields
