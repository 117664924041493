import { useSelector } from 'react-redux'
import {
  useCallback,
  useState,
} from 'react'
import {
  TabbedShowLayout,
  Tab,
  Title,
  useTranslate,
  Confirm,
  useNotify,
  useRefresh,
  Loading,
} from 'react-admin'
import {
  Card,
  CardContent,
  Box,
  Button,
  Grid,
} from '@material-ui/core'
import { ListHeader, ListBase } from '../custom'
import TonnageTicketInvoice from '../resources/tonnage-ticket-invoices/invoice'
import HourlyTicketInvoice from '../resources/hourly-ticket-invoices/invoice'
import EmployeeDriverPayroll from '../resources/employee-driver-pay-rollups/driver-payroll'
import BrokerDriverPayroll from '../resources/broker-driver-pay-rollups/driver-payroll'
import TonnageTicketInvoicesDatagrid from '../resources/tonnage-ticket-invoices/datagrid'
import TonnageTicketInvoicesCollectionData from '../resources/tonnage-ticket-invoices/collection-data'
import HourlyTicketInvoicesDatagrid from '../resources/hourly-ticket-invoices/datagrid'
import HourlyTicketInvoicesCollectionData from '../resources/hourly-ticket-invoices/collection-data'
import EmployeeDriverPayRollupsDatagrid from '../resources/employee-driver-pay-rollups/datagrid'
import EmployeeDriverPayRollupsCollectionData from '../resources/employee-driver-pay-rollups/collection-data'
import BrokerDriverPayRollupsDatagrid from '../resources/broker-driver-pay-rollups/datagrid'
import BrokerDriverPayRollupsCollectionData from '../resources/broker-driver-pay-rollups/collection-data'
import BrokerDriverProfitsDatagrid from '../resources/broker-driver-profits/datagrid'
import BrokerDriverProfitsCollectionData from '../resources/broker-driver-profits/collection-data'
import CertifiedPayrollReportsDatagrid from '../resources/certified-payroll-reports/datagrid'
import {
  useTransfer,
  useEarliestIncompletePeriod,
  useEnabledFeatures,
} from '../../hooks'
import { PrintListButtons, ToggleUiButton } from '../shared'
import { withCollectionData } from '../hoc'
import { dateFieldOptions } from '../util/component-options'
import { sorts } from '../../data-model'

const TonnageTicketInvoicesDatagridWithTotals = withCollectionData(TonnageTicketInvoicesDatagrid, TonnageTicketInvoicesCollectionData)
const HourlyTicketInvoicesDatagridWithTotals = withCollectionData(HourlyTicketInvoicesDatagrid, HourlyTicketInvoicesCollectionData)
const EmployeeDriverPayRollupsDatagridWithTotals = withCollectionData(EmployeeDriverPayRollupsDatagrid, EmployeeDriverPayRollupsCollectionData)
const BrokerDriverPayRollupsDatagridWithTotals = withCollectionData(BrokerDriverPayRollupsDatagrid, BrokerDriverPayRollupsCollectionData)
const BrokerDriverProfitsDatagridWithTotals = withCollectionData(BrokerDriverProfitsDatagrid, BrokerDriverProfitsCollectionData)

const TabContent = props => {
  const { record, reference, resource, filter, ...rest } = props

  return (
    <ListBase
      {...rest}
      resource={reference}
      includeHeader={false}
      filter={{
        include_collection_data: true,
        period_id_eq: props.record.id,
        ...filter,
      }}
    />
  )
}

const TonnageTicketInvoices = ({ showDetail }) => {
  return (
    <>
      <ListHeader
        actions={
          <>
            <PrintListButtons
              detail={<TonnageTicketInvoice showDetail={showDetail} />}
              summary={<TonnageTicketInvoicesDatagridWithTotals />}
            />
            <ToggleUiButton uiAttribute='tonnageTicketInvoiceShowDetail' />
          </>
        }
      />
      <TonnageTicketInvoicesDatagridWithTotals showDetail={showDetail} />
    </>
  )
}

const HourlyTicketInvoices = () => {
  return (
    <>
      <ListHeader
        actions={
          <PrintListButtons
            detail={<HourlyTicketInvoice/>}
            summary={<HourlyTicketInvoicesDatagridWithTotals/>}
          />
        }
      />
      <HourlyTicketInvoicesDatagridWithTotals />
    </>
  )
}

const EmployeeDriverPayRollups = props => {
  return (
    <>
      <ListHeader
        {...props}
        actions={
          <PrintListButtons
            summary={<EmployeeDriverPayRollupsDatagridWithTotals/>}
            detail={<EmployeeDriverPayroll/>}
            detailPrintOptions={{ landscape: true }}
          />
        }
      />
      <EmployeeDriverPayRollupsDatagridWithTotals />
    </>
  )
}

const BrokerDriverPayRollups = props => {
  return (
    <>
      <ListHeader
        {...props}
        actions={
          <PrintListButtons
            detail={<BrokerDriverPayroll/>}
            summary={<BrokerDriverPayRollupsDatagridWithTotals/>}
            detailPrintOptions={{ landscape: true }}
          />
        }
      />
      <BrokerDriverPayRollupsDatagridWithTotals />
    </>
  )
}

const BrokerDriverProfits = props => {
  return (
    <>
      <ListHeader
        {...props}
        actions={
          <PrintListButtons
            summary={<BrokerDriverProfitsDatagridWithTotals/>}
            summaryPrintOptions={{ landscape: true }}
          />
        }
      />
      <BrokerDriverProfitsDatagridWithTotals />
    </>
  )
}

const CertifiedPayrollReports = props => {
  return (
    <CertifiedPayrollReportsDatagrid {...props} />
  )
}

const TransferPreview = () => {
  const translationPrefix = 'resources.transfers.preview'
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()
  const period = useEarliestIncompletePeriod()
  const showInvoiceDetail = useSelector(state => state.ui.tonnageTicketInvoiceShowDetail)

  const {
    isLoaded: isEnabledFeaturesLoaded,
    isEnabled,
  } = useEnabledFeatures()

  const periodId = period?.id

  const [transfer, isTransferring] = useTransfer(periodId)

  const onTransferButtonClick = useCallback(() => {
    setConfirmIsOpen(true)
  }, [])

  const onConfirmClose = useCallback(() => {
    if (!isTransferring) {
      setConfirmIsOpen(false)
    }
  }, [isTransferring])

  const onConfirmTransfer = useCallback(async () => {
    const [transferError, downloadError] = await transfer()
    if (transferError) {
      notify(`${translationPrefix}.error`, 'error')
    } else {
      notify(`${translationPrefix}.success`)
      setConfirmIsOpen(false)
      refresh()
    }

    if (downloadError) {
      notify(`${translationPrefix}.downloadError`, 'error')
    }
  }, [notify, refresh, transfer, translationPrefix])

  if (!period || !isEnabledFeaturesLoaded) return <Loading />

  return (
    <>
      <Box>
        <Card>
          <Title title={translate(`${translationPrefix}.name`)} />
          <CardContent>
            <Grid container alignItems='center' spacing={2}>
              <Grid item>
                <Button
                  onClick={onTransferButtonClick}
                  variant='contained'
                  color='primary'
                  size='small'
                  disabled={isTransferring}
                >
                  {translate(`${translationPrefix}.action`)}
                </Button>
              </Grid>
              <Grid item>
                {`${translate('resources.periods.fieldNames.end_date').toUpperCase()}: ${new Date(period.end_date).toLocaleString([], dateFieldOptions)}`}
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <TabbedShowLayout record={period}>
              <Tab
                label={translate('resources.tonnage_ticket_invoices.name', { smart_count: 2 })}
                path='tonnage_ticket_invoices'
              >
                <TabContent
                  reference='tonnage_ticket_invoices'
                  sort={sorts['tonnage_ticket_invoices']}
                >
                  <TonnageTicketInvoices showDetail={showInvoiceDetail} />
                </TabContent>
              </Tab>
              <Tab
                label={translate('resources.hourly_ticket_invoices.name', { smart_count: 2 })}
                path='hourly_ticket_invoices'
              >
                <TabContent
                  reference='hourly_ticket_invoices'
                  sort={sorts['hourly_ticket_invoices']}
                >
                  <HourlyTicketInvoices />
                </TabContent>
              </Tab>
              <Tab
                label={translate('resources.employee_driver_pay_rollups.name', { smart_count: 2 })}
                path='employee_driver_pay_rollups'
              >
                <TabContent
                  reference='employee_driver_pay_rollups'
                  sort={sorts['employee_driver_pay_rollups']}
                >
                  <EmployeeDriverPayRollups />
                </TabContent>
              </Tab>
              <Tab
                label={translate('resources.broker_driver_pay_rollups.name', { smart_count: 2 })}
                path='broker_driver_pay_rollups'
              >
                <TabContent
                  reference='broker_driver_pay_rollups'
                  sort={sorts['broker_driver_pay_rollups']}
                >
                  <BrokerDriverPayRollups />
                </TabContent>
              </Tab>
              <Tab
                label={translate('resources.broker_driver_profits.name', { smart_count: 2 })}
                path='broker_driver_profits'
              >
                <TabContent
                  reference='broker_driver_profits'
                  sort={sorts['broker_driver_profits']}
                >
                  <BrokerDriverProfits />
                </TabContent>
              </Tab>
              {
                isEnabled('certified_payroll') &&
                <Tab
                  label='Certified reports'
                  path='certified_payroll_reports'
                >
                  <TabContent
                    reference='certified_payroll_reports'
                    filter={{ include_collection_data: false }}
                  >
                    <CertifiedPayrollReports />
                  </TabContent>
                </Tab>
              }
            </TabbedShowLayout>
          </CardContent>
        </Card>
      </Box>
      <Confirm
        isOpen={confirmIsOpen}
        loading={isTransferring}
        onConfirm={onConfirmTransfer}
        title={translate(`${translationPrefix}.confirmTitle`, { date: new Date(period.end_date).toLocaleString([], dateFieldOptions)})}
        content={isTransferring ? translate(`${translationPrefix}.isTransferring`) : translate(`${translationPrefix}.confirmContent`)}
        onClose={onConfirmClose}
      />
    </>
  )
}

export default TransferPreview
