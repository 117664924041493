import {
  TextInput,
  SelectInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import {
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form, getValidator } from '../../form'
import { AddressInputFields } from '../../shared'
import { DateInput, NumberInput, BooleanInput } from '../../custom'

const filingStatusChoices = [
  'married_filing_jointly',
  'single_or_married_filing_separately',
  'head_of_household',
].map(c => ({
  id: c,
  name: c.replaceAll('_', ' '),
}))

const EmployeeDriversForm = props => {
  const { resource } = props
  const formClasses = useFormStyles(props)
  const label = useLabel(props)

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <TextInput
            {...formInputOptions}
            source='last_name'
            label={label('last_name')}
            validate={getValidator()}
            required
          />

          <TextInput
            {...formInputOptions}
            source='first_name'
            label={label('first_name')}
            validate={getValidator()}
            required
          />

          <TextInput
            {...formInputOptions}
            source='ssn'
            label={label('ssn')}
          />

          <NumberInput
            {...formInputOptions}
            source='percent_of_base_rate'
            label={label('percent_of_base_rate')}
            validate={getValidator()}
            step={1}
            min={0}
            required
          />

          <DateInput
            {...formInputOptions}
            validate={getValidator(false)}
            source='hired_at_date'
            label={label('hired_at_date')}
          />

          <BooleanInput
            source='is_active'
            label={label('is_active')}
            defaultValue={true}
            tabIndex={-1}
          />
        </Box>

        <Box>
          <AddressInputFields resource={resource} includeCounty />
        </Box>

        <Box>
          <NumberInput
            {...formInputOptions}
            source='w4_submission_year'
            label={label('w4_submission_year')}
            step={1}
            min={2000}
            validate={getValidator(false)}
          />

          <BooleanInput
            source='w4_step_two_checkbox_is_checked'
            label={label('w4_step_two_checkbox_is_checked')}
            defaultValue={false}
          />

          <SelectInput
            {...formInputOptions}
            source='w4_filing_status'
            label={label('w4_filing_status')}
            choices={filingStatusChoices}
            validate={getValidator(false)}
          />

          <NumberInput
            {...formInputOptions}
            source='external_non_job_taxable_wages_per_year_in_dollars'
            label={label('external_non_job_taxable_wages_per_year_in_dollars')}
            step={1}
            min={0}
            validate={getValidator(false)}
          />

          <NumberInput
            {...formInputOptions}
            source='tax_credits_in_dollars'
            label={label('tax_credits_in_dollars')}
            step={1}
            min={0}
            validate={getValidator(false)}
          />

          <NumberInput
            {...formInputOptions}
            source='personally_claimed_deductions_in_dollars'
            label={label('personally_claimed_deductions_in_dollars')}
            step={1}
            min={0}
            validate={getValidator(false)}
          />

          <NumberInput
            {...formInputOptions}
            source='number_of_federal_allowances'
            label={label('number_of_federal_allowances')}
            step={1}
            min={0}
            validate={getValidator(false)}
          />

          <NumberInput
            {...formInputOptions}
            source='number_of_personal_state_exemptions'
            label={label('number_of_personal_state_exemptions')}
            step={1}
            min={0}
            validate={getValidator(false)}
          />

          <NumberInput
            {...formInputOptions}
            source='number_of_dependent_state_exemptions'
            label={label('number_of_dependent_state_exemptions')}
            step={1}
            min={0}
            validate={getValidator(false)}
          />

          <NumberInput
            {...formInputOptions}
            source='additional_fed_withholding_in_dollars'
            label={label('additional_fed_withholding_in_dollars')}
            step={1}
            min={0}
            validate={getValidator(false)}
          />

          <NumberInput
            {...formInputOptions}
            source='additional_state_withholding_in_dollars'
            label={label('additional_state_withholding_in_dollars')}
            step={1}
            min={0}
            validate={getValidator(false)}
          />

          <NumberInput
            {...formInputOptions}
            source='additional_county_withholding_in_dollars'
            label={label('additional_county_withholding_in_dollars')}
            step={1}
            min={0}
            validate={getValidator(false)}
          />
        </Box>
      </Box>
    </Form>
  )
}

export default EmployeeDriversForm
