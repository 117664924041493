import React, { useCallback, useState } from 'react'
import { InputHelperText } from 'react-admin'
import { useInput, FieldTitle } from 'ra-core'
import {
  KeyboardDatePicker,
  // KeyboardTimePicker,
  // KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { iSODateToDateOnlyString, dateOnlyStringToISODate } from '../../util/date'
import DateFnsUtils from '@date-io/date-fns'

const providerOptions = {
  utils: DateFnsUtils,
  locale: undefined,
}

const Picker = ({ PickerComponent, ...fieldProps }) => {
  const {
    label,
    source,
    resource,
    className,
    variant,
    required,
    helperText,
    fullWidth,
    margin,
    validate,
    disabled,
    shouldDisableDate,
    initialFocusedDate,
    defaultValue,
  } = fieldProps

  const { input, meta, isRequired } = useInput({ source, validate })
  const { touched, error: validationError, submitError } = meta
  const error = submitError || validationError

  const [value, setValue] = useState(() => {
    if (Date.parse(input.value)) {
      return dateOnlyStringToISODate(input.value)
    } else if (defaultValue) {
      return dateOnlyStringToISODate(defaultValue)
    } else {
      return null
    }
  })

  const formatInput = useCallback((date) => {
    return Date.parse(date) ? iSODateToDateOnlyString(date) : null
  }, [])

  const handleChange = useCallback(date => {
    setValue(date)
    input.onChange(formatInput(date))
  }, [input, setValue, formatInput])

  const onBlur = useCallback(() => {
    input.onBlur(formatInput(value))
  }, [value, input, formatInput])

  return (
    <MuiPickersUtilsProvider {...providerOptions}>
      <PickerComponent
        disableToolbar
        shouldDisableDate={shouldDisableDate}
        disabled={disabled}
        className={className}
        value={value}
        initialFocusedDate={initialFocusedDate ? dateOnlyStringToISODate(initialFocusedDate) : undefined}
        fullWidth={fullWidth}
        margin={margin}
        required={isRequired || required}
        inputVariant={variant}
        error={Boolean(touched && error)}
        onChange={handleChange}
        onBlur={onBlur}
        format='MM/dd/yyyy'
        variant='inline'
        KeyboardButtonProps={{ tabIndex: '-1' }}
        helperText={<InputHelperText
          touched={touched}
          error={error}
          helperText={helperText}
        />}
        label={<FieldTitle
          label={label}
          source={source}
          resource={resource}
        />}
      />
    </MuiPickersUtilsProvider>
  )
}

// Most of this component can be generalized to use the time/datetime pickers suggested below,
// but when involving time we cannot use date only conversions.

const DateInput = props => <Picker PickerComponent={KeyboardDatePicker} {...props} />
// const TimeInput = props => <Picker PickerComponent={KeyboardTimePicker} {...props} />
// const DateTimeInput = props => <Picker PickerComponent={KeyboardDateTimePicker} {...props} />

export {
  DateInput,
  // TimeInput,
  // DateTimeInput,
}
