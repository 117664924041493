import React from 'react'
import {
  ReferenceInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { Autocomplete, NumberInput } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { relationships } from '../../../data-model'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form, getValidator } from '../../form'

const ZoneRatesForm = props => {
  const { resource } = props
  const formClasses = useFormStyles(props)
  const label = useLabel(props)
  const resourceRelationships = relationships[resource]

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <ReferenceInput
            {...referenceInputOptions(resourceRelationships['zone_1'])}
            {...formInputOptions}
            source='zone_1.id'
            reference={resourceRelationships['zone_1']}
            label={label('zone_1')}
          >
            <Autocomplete
              validate={getValidator()}
              required
            />
          </ReferenceInput>

          <ReferenceInput
            {...referenceInputOptions(resourceRelationships['zone_2'])}
            {...formInputOptions}
            source='zone_2.id'
            reference={resourceRelationships['zone_2']}
            label={label('zone_2')}
          >
            <Autocomplete
              validate={getValidator()}
              required
            />
          </ReferenceInput>

          <NumberInput
            {...formInputOptions}
            source='rate_in_dollars'
            label={label('rate_in_dollars')}
            step={0.01}
            min={0}
            validate={getValidator()}
            required
          />
        </Box>
      </Box>
    </Form>
  )
}

export default ZoneRatesForm
