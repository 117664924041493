import React, { useCallback } from 'react'
import {
  ReferenceInput,
  TextInput,
  required,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { Autocomplete, NumberInput } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { relationships } from '../../../data-model'
import { Form, getValidator } from '../../form'
import { useHourlyRateFields, useReferenceLookup } from '../../form/functions'

const certifiedFields = [
  'certified_location',
  'certified_project_name',
]

const HourlyRatesForm = (props) => {
  const { resource, record } = props
  const resourceRelationships = relationships[resource]
  const formClasses = useFormStyles(props)
  const rateFields = useHourlyRateFields()
  const label = useLabel(props)
  const {
    selectedRecord: selectedPayscale,
    onSelectedIdChange: onSelectedPayscaleIdChange,
    findRecordFromStore: findPayscaleFromStore,
  } = useReferenceLookup(resourceRelationships['payscale'], record.payscale)

  const isPayscaleCertified = selectedPayscale && selectedPayscale.is_certified

  const getCertifieldFieldValidator = useCallback(_required => {
    return (value, data, field) => {
      if (!findPayscaleFromStore(data.payscale?.id)?.is_certified) {
        return undefined
      } else {
        return [
          _required && required(),
        ].filter(Boolean).reduce((error, validator) => error || validator(value, data, field), undefined)
      }
    }
  }, [findPayscaleFromStore])

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <TextInput
            {...formInputOptions}
            source='code'
            label={label('code')}
            validate={getValidator(false)}
          />

          <TextInput
            {...formInputOptions}
            source='description'
            label={label('description')}
            validate={getValidator()}
            required
          />

          {
            rateFields.map(({ source, required, label: _label }) => (
              <NumberInput
                {...formInputOptions}
                key={source}
                validate={getValidator(required)}
                source={source}
                label={label(_label)}
                step={0.01}
                min={0}
                required={required}
              />
            ))
          }
        </Box>

        <Box>
          <ReferenceInput
            {...referenceInputOptions(resourceRelationships['customer'])}
            {...formInputOptions}
            source={'customer.id'}
            reference={resourceRelationships['customer']}
            label={label('customer')}
            required
          >
            <Autocomplete validate={getValidator()} />
          </ReferenceInput>

          <ReferenceInput
            {...referenceInputOptions(resourceRelationships['payscale'])}
            {...formInputOptions}
            source={'payscale.id'}
            reference={resourceRelationships['payscale']}
            label={label('payscale')}
            onChange={onSelectedPayscaleIdChange}
            required
          >
            <Autocomplete
              validate={getValidator()}
              optionText='type_of_payscale'
            />
          </ReferenceInput>

          <ReferenceInput
            {...referenceInputOptions(resourceRelationships['work_class'])}
            {...formInputOptions}
            source='work_class.id'
            reference={resourceRelationships['work_class']}
            label={label('work_class')}
          >
            <Autocomplete
              validate={getValidator(false)}
            />
          </ReferenceInput>

          {
            certifiedFields.map(f => (
              <TextInput
                {...formInputOptions}
                key={f}
                source={f}
                label={label(f)}
                disabled={!isPayscaleCertified}
                required={isPayscaleCertified}
                validate={getCertifieldFieldValidator(true)}
              />
            ))
          }
        </Box>
      </Box>
    </Form>
  )
}

export default HourlyRatesForm
