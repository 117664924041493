import React from 'react'
import { Create } from 'react-admin'
import Form from './form'

const WageCoefficientsCreate = props => {
  return (
    <Create {...props} >
      <Form />
    </Create>
  )
}

export default WageCoefficientsCreate
