import { useState, useCallback } from 'react'
import Icon from '@material-ui/icons/Edit'
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  withStyles,
  makeStyles,
} from '@material-ui/core'
import { useTranslate } from 'ra-core'
import { useTranslateResource } from '../../../hooks'
import { NumberInput } from '../../custom'
import { formInputOptions } from '../../util/component-options'
import { Form } from 'react-final-form'

const emptyRecord = {}
const inputName = 'adjustment'

const StyledDialog = withStyles(() => {
  return {
    root: {
      overflow: 'auto',
    },
    container: {
      height: 'auto',
    },
    paper: {
      marginTop: '20vh',
      marginBottom: '20vh',
    },
  }
})(Dialog)

const useStyles = makeStyles(theme => ({
  dialogFooter: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

const AdjustNumeric = ({ attribute, resource, onAccept, disabled, value: attributeValue, roundFactor = 100, step = 0.1 }) => {
  const translateResource = useTranslateResource(resource, 'fieldNames')
  const translate = useTranslate()
  const [showModal, setShowModal] = useState(false)
  const classes = useStyles()

  const onClick = useCallback(() => {
    setShowModal(true)
  }, [])

  const onModalClose = useCallback(() => {
    setShowModal(false)
  }, [])

  const getAdjustedValue = useCallback(adjustment => {
    const raw = (1 + ((adjustment ?? 0) / 100)) * attributeValue
    return Math.round((raw +  Number.EPSILON) * roundFactor) / roundFactor
  }, [attributeValue, roundFactor])

  const onSubmit = useCallback((values) => {
    setShowModal(false)
    const adjustment = values[inputName]
    const value = getAdjustedValue(adjustment)
    onAccept(value)
  }, [getAdjustedValue, onAccept])

  return (
    <>
      <IconButton
        onClick={onClick}
        tabIndex={-1}
        size='small'
        disabled={disabled}
      >
        <Icon fontSize='small' />
      </IconButton>
      {
        <StyledDialog
          maxWidth='sm'
          fullWidth
          open={showModal}
          onClose={onModalClose}
        >
          <DialogTitle>
            {`${translate('adjustNumeric.title')} ${translateResource(attribute)}`}
          </DialogTitle>
          <Form
            onSubmit={onSubmit}
            subscription={{ values: true}}
            render={formProps => {
              const value = formProps.values[inputName]
              const adjustedValue = getAdjustedValue(value)

              return (
                <form onSubmit={formProps.handleSubmit}>
                  <DialogContent>
                    <NumberInput
                      {...formInputOptions}
                      record={emptyRecord}
                      source={inputName}
                      step={step}
                      autoFocus
                      helperText={translate('adjustNumeric.helperText')}
                    />
                    <Grid container spacing={4} alignItems='center'>
                      <Grid item>
                        <Typography >
                          {`${translate('adjustNumeric.currentValue')}: ${attributeValue}`}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography >
                          {`${translate('adjustNumeric.adjustedValue')}: ${adjustedValue}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.dialogFooter} spacing={2} alignItems='center' justify='flex-end'>
                      <Grid item>
                        <Button
                          color='primary'
                          variant='contained'
                          type='submit'
                          disabled={!value}
                        >
                          {translate('adjustNumeric.accept')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant='contained'
                          onClick={onModalClose}
                        >
                          {translate('adjustNumeric.cancel')}
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </form>
              )
            }}
          />
        </StyledDialog>
      }
    </>
  )
}

export default AdjustNumeric
