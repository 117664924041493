import { NumberField, useListContext } from 'react-admin'
import { CollectionDataTable } from '../../shared'
import { currencyFieldOptions } from '../../util/component-options'
import { useLabel } from '../../../hooks'

const CollectionData = props => {
  const context = useListContext()
  const label = useLabel(context)
  
  return (
    <CollectionDataTable {...props}>
      <NumberField source='tickets_regular_total_in_dollars' label={label('tickets_regular_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='tickets_overtime_total_in_dollars' label={label('tickets_overtime_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='tickets_doubletime_total_in_dollars' label={label('tickets_doubletime_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='tickets_total_in_dollars' label={label('tickets_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='minimum_hourly_wage_adjustment_in_dollars' label={label('minimum_hourly_wage_adjustment_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='total_in_dollars' label={label('total_in_dollars')} options={currencyFieldOptions} />
    </CollectionDataTable>
  )
}

export default CollectionData
