import { useCallback } from 'react'
import { usePermissions } from 'react-admin'

const useEnabledFeatures = () => {
  const {
    loaded: isLoaded,
    loading: isLoading,
    permissions,
  } = usePermissions()

  const isEnabled = useCallback((feature) => {
    if (!isLoaded) return false
    return permissions.enabledFeatures.includes(feature)
  }, [permissions, isLoaded])

  return {
    isEnabled,
    isLoaded,
    isLoading,
  }
}

export default useEnabledFeatures
