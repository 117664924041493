import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  IconButton,
} from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print'
import usePrint from '../../hooks/use-print'

const useStyles = makeStyles(theme => {
  return {
    container: {
      marginRight: theme.spacing(-1.75),
      marginLeft: theme.spacing(-1.75),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    reportCol: {
      flexGrow: 1,
    },
  }
})

const CertifiedPayrollReportGridExpand = ({ children }) => {
  const [
    print,
    Printable,
    willPrint,
  ] = usePrint({ landscape: true })

  const classes = useStyles()

  return (
    <Grid container spacing={1} wrap='nowrap' className={classes.container}>
      <Grid item>
        <IconButton
          size='small'
          color='secondary'
          onClick={print}
          disabled={willPrint}
        >
          <PrintIcon />
        </IconButton>
      </Grid>
      <Grid item className={classes.reportCol}>
        <Card>
          <CardContent>
            <Printable renderChildren>
              {children}
            </Printable>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default CertifiedPayrollReportGridExpand
