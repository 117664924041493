import {
  NumberField,
  useTranslate,
} from 'react-admin'
import {
  Grid,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core'
import {
  currencyFieldOptions,
  dateFieldOptions,
} from '../util/component-options'

const useStyles = makeStyles(theme => {
  return {
    spaced: {
      marginBottom: theme.spacing(4)
    },
    total: {
      ...theme.typography.body1,
      fontWeight: theme.typography.fontWeightBold,
    }
  }
})

const DriverField = ({ record }) => {
  return (
    <>
      <Typography>
        {record.name}
      </Typography>
      <Typography>
        {record.address_street_1}
      </Typography>
      { record.address_street_2 &&
        <Typography>
          {record.address_street_2}
        </Typography>
      }
      <Typography>
        {`${record.address_city}, ${record.address_united_state_abbreviation} ${record.address_zip}`}
      </Typography>
    </>
  )
}

const TenantField = ({ record }) => {
  return (
    <>
      <Typography>
        {record.name}
      </Typography>
      <Typography>
        {record.address_street_1}
      </Typography>
      <Typography>
        {`${record.address_city}, ${record.address_united_state_abbreviation} ${record.address_zip}`}
      </Typography>
    </>
  )
}

const DriverPayroll = ({
  driver,
  total,
  detail,
  date,
  lineItems,
  tenant,
}) => {

  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Box>
      <Grid container className={classes.spaced}>
        <Grid item xs={6}>
          <TenantField record={tenant} />
        </Grid>
        <Grid item xs={6}>
          <Typography align='right'>
            {new Date(date).toLocaleString([], dateFieldOptions)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.spaced}>
        <Grid item xs={6}>
          <Typography>
            {`${translate('payroll.driver')}:`}
          </Typography>
          <DriverField record={driver} />
        </Grid>
        <Grid item xs={6}>
          {detail}
        </Grid>
      </Grid>

      <Grid container className={classes.spaced}>
        {lineItems}
      </Grid>

      <Grid container justify='flex-end'>
        <Typography align='right' className={classes.total}>
          {`${translate('payroll.total')}:`}
          &nbsp;
        </Typography>
        <NumberField className={classes.total} record={{ total }} source='total' options={currencyFieldOptions} />
      </Grid>
    </Box>
  )
}

export default DriverPayroll
