import { useMemo } from 'react'

const useAddressFields = (prefix = 'address') => (
  useMemo(() => ([
    {
      source: `${prefix}_street_1`,
      label: 'address.street_1',
    },
    {
      source: `${prefix}_street_2`,
      label: 'address.street_2',
    },
    {
      source: `${prefix}_city`,
      label: 'address.city',
    },
    {
      source: `${prefix}_united_state_abbreviation`,
      label: 'address.state',
    },
    {
      source: `${prefix}_zip`,
      label: 'address.zip',
    },
  ]),[prefix])
);

export default useAddressFields
