import React from 'react'
import {
  TextInput,
  ReferenceInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { Autocomplete, NumberInput } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { relationships } from '../../../data-model'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form, getValidator } from '../../form'

const MaterialSalePricesForm = props => {
  const { resource } = props
  const formClasses = useFormStyles(props)
  const label = useLabel(props)
  const resourceRelationships = relationships[resource]

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <ReferenceInput
            {...referenceInputOptions(resourceRelationships['material'])}
            {...formInputOptions}
            source='material.id'
            reference={resourceRelationships['material']}
            label={label('material')}
          >
            <Autocomplete
              validate={getValidator()}
              required
            />
          </ReferenceInput>

          <ReferenceInput
            {...referenceInputOptions(resourceRelationships['pickup_site'])}
            {...formInputOptions}
            source='pickup_site.id'
            reference={resourceRelationships['pickup_site']}
            label={label('pickup_site')}
          >
            <Autocomplete
              validate={getValidator()}
              required
            />
          </ReferenceInput>

          <NumberInput
            {...formInputOptions}
            source='price_in_dollars'
            label={label('price_in_dollars')}
            step={0.01}
            min={0}
            validate={getValidator()}
            required
          />

          <TextInput
            {...formInputOptions}
            source='name'
            label={label('name')}
            validate={getValidator(false)}
          />
        </Box>
      </Box>
    </Form>
  )
}

export default MaterialSalePricesForm
