import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  iconWrapper: {
    display: 'flex',
  },
}))

const ButtonWithIcon = ({ icon, label, ...rest}) => {
  const classes = useStyles()
  return (
    <Button {...rest}>
      <Grid container alignItems='center' spacing={1}>
        <Grid item className={classes.iconWrapper}>
          {icon}
        </Grid>
        <Grid item>
          {label}
        </Grid>
      </Grid>
    </Button>
  )
}

export default ButtonWithIcon
