import React, { useCallback } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import { FieldTitle, useInput, ValidationError, useTranslate } from 'ra-core'

// Patch BooleanInput to pass tabindex to underlying input

const InputHelperText = ({
  helperText,
  touched,
  error,
}) => {
  const translate = useTranslate()

  return touched && error ? (
    <ValidationError error={error} />
  ) : typeof helperText === 'string' ? (
    <>{translate(helperText, { _: helperText })}</>
  ) : helperText !== false ? (
    // material-ui's HelperText cannot reserve space unless we pass a single
    // space as child, which isn't possible when the child is a component.
    // Therefore, we must reserve the space ourselves by passing the same
    // markup as material-ui.
    // @see https://github.com/mui-org/material-ui/blob/62e439b7022d519ab638d65201e204b59b77f8da/packages/material-ui/src/FormHelperText/FormHelperText.js#L85-L90
    // eslint-disable-next-line react/no-danger
    <span dangerouslySetInnerHTML={defaultInnerHTML} />
  ) : null
}

const defaultInnerHTML = { __html: '&#8203;' }

const BooleanInput = ({
  format,
  label,
  fullWidth,
  helperText,
  onBlur,
  onChange,
  onFocus,
  options,
  disabled,
  parse,
  resource,
  source,
  validate,
  tabIndex,
  required,
  ...rest
} = {}) => {
  const {
    id,
    input: { onChange: finalFormOnChange, type, value, ...inputProps },
    isRequired,
    meta: { error, submitError, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'checkbox',
    validate,
    isRequired: required,
    ...rest,
  })

  const handleChange = useCallback((event, value) => {
    finalFormOnChange(value)
  }, [finalFormOnChange])

  return (
    <FormGroup {...rest}>
      <FormControlLabel
        control={
          <Switch
            id={id}
            color='primary'
            onChange={handleChange}
            {...inputProps}
            {...options}
            disabled={disabled}
            tabIndex={tabIndex}
          />
        }
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
      />
      <FormHelperText error={!!(error || submitError)}>
        <InputHelperText
          touched={touched}
          error={error || submitError}
          helperText={helperText}
        />
      </FormHelperText>
    </FormGroup>
  )
}
export default BooleanInput
