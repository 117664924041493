import React from 'react'
import { Box } from '@material-ui/core'
import {
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form, getValidator } from '../../form'
import { NumberInput } from '../../custom'

const WageCoefficientsForm = props => {
  const formClasses = useFormStyles(props)
  const label = useLabel(props)

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <NumberInput
            {...formInputOptions}
            source='amt_in_hours'
            label={label('amt_in_hours')}
            step={0.25}
            min={0}
            validate={getValidator()}
            required
          />

          <NumberInput
            {...formInputOptions}
            source='rate'
            label={label('rate')}
            step={0.001}
            min={0}
            validate={getValidator()}
            required
          />
        </Box>
      </Box>
    </Form>
  )
}

export default WageCoefficientsForm
