import { useMemo } from 'react'
import { useRedirect, useTranslate, Edit } from 'react-admin'
import Form from './form'
import { editOptions } from '../../util/component-options'
import { Grid, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const resource = 'tenants'

const useStyles = makeStyles(({ spacing }) => {
  return {
    container: {
      alignItems: 'stretch',
      padding: spacing(4),
    },
    formGrid: {
      flexBasis: '75%',
      flexGrow: 1,
      margin: -spacing(4), // hacky assumption - undo form styling
    },
    smsNumbersGrid: {
      flexBasis: '25%',
      flexShrink: 1,
    },
    smsTitle: {
      marginBottom: spacing(1),
      fontSize: '1.1rem',
      textTransform: 'capitalize',
    },
    smsNumber: {
      marginBottom: spacing(0.5),
      whiteSpace: 'nowrap',
    },
  }
})

const EditView = (props) => {
  const { record } = props
  const classes = useStyles()
  const translate = useTranslate()
  const redirect = useRedirect()

  const formOptions = useMemo(() => ({
    onUpdateSuccess: () => {
      redirect('/')
    },
  }), [redirect]);

  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item className={classes.formGrid}>
        <Form
          {...formOptions}
          {...props}
          isDeleteDisabled
        />
      </Grid>
      {
        Boolean(record.sms_numbers.length) &&
        <Grid item className={classes.smsNumbersGrid}>
          <Box className={classes.uploadWrapper}>
            <Typography className={classes.smsTitle}>
              { translate('resources.sms_numbers.name', { smart_count: 2 }) }
            </Typography>
            {
              record.sms_numbers.map(r => {
                return (
                  <Typography key={r.number} className={classes.smsNumber}>
                    {r.formatted_number}
                  </Typography>
                )
              })
            }
          </Box>
        </Grid>
      }
    </Grid>
  )
}

const TenantsEdit = () => {
  return (
    <Edit
      {...editOptions}
      id='0'
      resource={resource}
      basePath={`/${resource}`}
    >
      <EditView />
    </Edit>
  )
}
export default TenantsEdit
