import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => {
  const pad = spacing(4)
  
  return {
    form: {
      padding: pad,
    },
    grid: {
      display: 'grid',
      gridGap: pad,
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    },
  }
})

export default useStyles
