import { useCallback } from 'react'
import {
  TextInput,
  FileInput,
  FileField,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form, getValidator } from '../../form'

const StyledFileInput = withStyles((theme) => ({
  root: {
    margin: 0,
  },
}))(FileInput)

const TonnageTicketPdfUploadsForm = props => {
  const formClasses = useFormStyles(props)
  const label = useLabel(props)
  const { record, isUpdateDisabled } = props

  const transform = useCallback(data => {
    return {
      id: data.id,
      pdf_file: data.fileUpload?.rawFile,
    }
  }, [])

  return (
    <Form
      {...props}
      transform={transform}
    >
      <Box className={formClasses.grid}>
        {
          (record.pdf_filename || record.pdf_file_url) &&
          <Box>
            <TextInput
              {...formInputOptions}
              source='pdf_filename'
              label={label('pdf_filename')}
              disabled
            />
            <FileField
              addLabel={false}
              source='pdf_file_url'
              title='pdf_filename'
              target='_blank'
            />
          </Box>
        }
        {
          !isUpdateDisabled &&
          <Box>
            <StyledFileInput
              source='fileUpload'
              accept='.pdf'
              label={''}
              validate={getValidator()}
              required
            >
              <FileField source='src' title='title' target='_blank'/>
            </StyledFileInput>
          </Box>
        }
      </Box>
    </Form>
  )
}

export default TonnageTicketPdfUploadsForm

