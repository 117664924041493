const tonnageInvoiceGroupingTypes = [
  'none',
  'dropoff_site',
  'rate',
]

const tonnageInvoiceGroupingChoices = tonnageInvoiceGroupingTypes.map(id => ({
  id,
  name: `resources.companies.tonnage_invoice_grouping_type.${id}`,
}))

export {
  tonnageInvoiceGroupingChoices,
}
