import React from 'react'
import {
  NumberField,
} from 'react-admin'
import { Datagrid, List } from '../../custom'
import { useLabel } from '../../../hooks'
import { numberFieldOptions } from '../../util/component-options'

const WageCoefficientsList = props => {
  const label = useLabel(props)
  
  return (
    <List
      {...props}
      perPage={1000}
      pagination={false}
    >
      <Datagrid>
        <NumberField source='amt_in_hours' label={label('amt_in_hours')} options={numberFieldOptions}/>
        <NumberField source='rate' label={label('rate')} options={{ maximumFractionDigits: 3, minimumFractionDigits: 3 }}/>
      </Datagrid>
    </List>
  )
}

export default WageCoefficientsList
