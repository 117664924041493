 const cancelablePromise = promise => {
  const isCanceled = { value: false }
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(d => {
      return isCanceled.value ? reject(isCanceled) : resolve(d)
    })
    .catch(e => {
      reject(isCanceled.value ? isCanceled : e)
    })
  })

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled.value = true
    },
  }
}

export default cancelablePromise
