import { useState, useCallback } from 'react'
import { useForm } from 'react-final-form'
import get from 'lodash/get'
import { useDidMountEffect, usePrevious } from '../../../hooks'

const useAutofillFields = (fields, autofillSource, shouldAutofill) => {
  const { change: changeFormField, getFieldState, resetFieldState } = useForm()

  const prevAutofillSource = usePrevious(autofillSource)

  const [autofilledState, setAutofilledState] = useState(() => {
    return fields.reduce((accum, field) => {
      return {
        ...accum,
        [field]: {
          autofilled: false,
        },
      }
    }, {})
  })

  const [overrideState, setOverrideState] = useState(() => {
    return fields.reduce((accum, field) => {
      return {
        ...accum,
        [field]: false
      }
    }, {})
  })

  const onAutofillFieldChange = useCallback((field) => {
    const fieldState = getFieldState(field)
    const { autofillValue } = autofilledState[field]
    
    setOverrideState(prev => {
      const hasOverride = autofillValue !== fieldState.value
      return {
        ...prev,
        [field]: hasOverride,
      }
    })
  }, [getFieldState, autofilledState])

  const getIsAutofilled = useCallback(field => {
    const { autofilled } = autofilledState[field]
    const hasOverride = overrideState[field]

    return autofilled && !hasOverride
  }, [autofilledState, overrideState])
  
  useDidMountEffect(() => {
    if (prevAutofillSource === autofillSource) return
    
    fields.forEach(field => {
      const fieldState = getFieldState(field)
      const nextValue = autofillSource ? get(autofillSource, field) : fieldState.initial
      const hasOverride = overrideState[field]
      const willAutofill = !(hasOverride && fieldState.dirty) && shouldAutofill && shouldAutofill(autofillSource, nextValue)

      setAutofilledState(prev => {
        return {
          ...prev,
          [field]: {
            ...prev[field],
            autofillValue: nextValue,
            autofilled: Boolean(willAutofill && autofillSource),
          },
        }
      })

      if (willAutofill) {
        changeFormField(field, nextValue)
        resetFieldState(field)
      }
    })
  }, [autofillSource, overrideState, shouldAutofill, fields, getFieldState, changeFormField, resetFieldState])

  return [
    getIsAutofilled,
    onAutofillFieldChange,
  ]
}

export default useAutofillFields
