import { useCallback, useRef, useEffect } from 'react'
import { useNonce } from '../../../hooks'

const useEnterKeyToTab = () => {
  const saveButtonRef = useRef()
  const formRef = useRef()
  const nextElementRef = useRef()

  // use nonce to trigger useEffect callback
  const [advanceKey, resetAdvanceKey] = useNonce()

  const getNextFocusElement = useCallback((currentElement) => {
    const { activeElement } = document
    if (currentElement !== activeElement) return null

    const formElements = 'button, input'
    const closestNonFocusableSelector = formElements.split(',').map(l => `${l.trim()}[tabindex='-1']`)

    const eligibleElements = [...formRef.current.querySelectorAll(formElements)].filter(el => {
      const visible = el.offsetWidth > 0 || el.offsetHeight > 0
      const focusable = !(el.disabled || el.tabIndex === -1 || el.closest(closestNonFocusableSelector))
      return (visible && focusable) || el === activeElement
    })

    const index = eligibleElements.indexOf(activeElement)
    return index > -1 ? (eligibleElements[index + 1] || eligibleElements[0]) : null
  }, [])

  const onKeyDown = useCallback(e => {
    if (e.keyCode === 13) {
      const currentElement = e.target
      const saveButton = saveButtonRef.current
      let nextElement = getNextFocusElement(currentElement)
      
      // Don't want to focus to submit button and submit form in the same keypress
      if (nextElement === saveButton) e.preventDefault()

      // Wait for re-paint before triggering advance
      window.requestAnimationFrame(() => {
        nextElement = getNextFocusElement(currentElement)
        nextElementRef.current = nextElement
        resetAdvanceKey()
      })
    }
  }, [getNextFocusElement, resetAdvanceKey])

  // useEffect lets react finish updates before advancing focus/select
  useEffect(() => {
    const nextElement = nextElementRef.current
    if (nextElement) {
      nextElement.focus()
      nextElement.select?.()
    }
  }, [advanceKey])

  return {
    onKeyDown,
    saveButtonRef,
    formRef,
  }
}

export default useEnterKeyToTab
