import {
  Edit,
  TabbedShowLayout,
  Tab,
} from 'react-admin'
import TonnageForm from '../tonnage-tickets/form'
import HourlyForm from '../hourly-tickets/form'
import { editOptions } from '../../util/component-options'
import { useTranslateResource } from '../../../hooks'
import { withTicketImage } from '../../hoc'
import { withStyles } from '@material-ui/core/styles'

const StyledTabbedShowLayout = withStyles(() => {
  return {
    content: {
      padding: 0,
    },
  }
})(TabbedShowLayout)

const TonnageFormWithTicketImage = withTicketImage(TonnageForm)
const HourlyFormWithTicketImage = withTicketImage(HourlyForm)

const Hourly = (props) => {
  const resource = 'pending_hourly_tickets'
  const translate = useTranslateResource(resource, 'form')

  return (
    <HourlyFormWithTicketImage
      {...props}
      resource={resource}
      saveButtonLabel={translate('edit.saveButtonLabel')}
      basePath='/pending_tonnage_tickets'
      useOptimisticSave={false}
    />
  )
}

const Tonnage = (props) => {
  const resource = 'pending_tonnage_tickets'
  const translate = useTranslateResource(resource, 'form')

  return (
    <TonnageFormWithTicketImage
      {...props}
      resource='pending_tonnage_tickets'
      saveButtonLabel={translate('edit.saveButtonLabel')}
      basePath='/pending_tonnage_tickets'
      useOptimisticSave={false}
    />
  )
}

const EditView = (props) => {
  const { record } = props
  const imageUrl = record?.tonnage_ticket_extraction?.ticket_image_url
  const senderPhone = record?.tonnage_ticket_extraction?.sender?.phone

  return (
    <StyledTabbedShowLayout
      record={record}
    >
      <Tab
        label='tonnage'
        path='tonnage'
      >
        <Tonnage
          imageUrl={imageUrl}
          senderPhone={senderPhone}
        />
      </Tab>
      <Tab
        label='hourly'
        path='hourly'
        resource='pending_hourly_tickets'
      >
        <Hourly
          imageUrl={imageUrl}
          senderPhone={senderPhone}
        />
      </Tab>
    </StyledTabbedShowLayout>
  )
}

const PendingTonnageTicketsEdit = props => {
  return (
    <Edit {...editOptions} {...props}>
      <EditView />
    </Edit>
  )
}

export default PendingTonnageTicketsEdit
