export default {
  fieldPrefixes: {
    filter: 'filter',
    find: 'find',
    start: 'start',
    end: 'end',
    earliest: 'earliest',
    latest: 'latest',
  },
  fieldSuffixes: {
    greaterThan: 'greater than',
  },
  invoice: {
    invoice: 'Invoice',
    sold_to: 'Sold to',
  },
  uploadedTicket: {
    sender: 'Sender',
  },
  payroll: {
    driver: 'Driver',
    total: 'Total',
  },
  printButtons: {
    summary: 'Summary',
    detail: 'Detail',
  },
  searchTitles: {
    tickets: 'Lookup ticket',
  },
  revenue: {
    endDate: 'End:',
    startDate: 'Start:',
    byDate: 'by day',
  },
  adjustNumeric: {
    title: 'Adjust',
    currentValue: 'Current',
    adjustedValue: 'Adjusted',
    helperText: 'Enter as a percentage (e.g. 5.5 for 5.5%)',
    accept: 'Accept',
    cancel: 'Cancel',
  },
  ui: {
    tonnageTicketInvoiceShowDetail: {
      false: 'Show invoice tickets',
      true: 'Hide invoice tickets',
    },
  },
}
