const conditionallyVisibleRateFields = [
  'fuel_surcharge_in_dollars',
  'material_surcharge_in_dollars',
  'equipment_rate_in_dollars',
  'material_sale_price_in_dollars',
  'sales_tax_in_dollars',
]

export {
  conditionallyVisibleRateFields,
}
