import React from 'react'
import {
  TextInput,
  BooleanInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import {
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form, getValidator } from '../../form'
import { NumberInput } from '../../custom'

const PayscalesForm = props => {
  const { record } = props
  const isNewRecord = Boolean(record.id)
  const formClasses = useFormStyles(props)
  const label = useLabel(props)

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <TextInput
            {...formInputOptions}
            source='code'
            label={label('code')}
            validate={getValidator()}
            required
          />

          <TextInput
            {...formInputOptions}
            source='type_of_payscale'
            label={label('type_of_payscale')}
            validate={getValidator()}
            required
          />

          <NumberInput
            {...formInputOptions}
            source='regular_pay_rate_in_dollars'
            label={label('regular_pay_rate_in_dollars')}
            step={0.01}
            min={0}
            validate={getValidator()}
            required
          />

          <NumberInput
            {...formInputOptions}
            source='regular_travel_time_pay_rate_in_dollars'
            label={label('regular_travel_time_pay_rate_in_dollars')}
            step={0.01}
            min={0}
            validate={getValidator(false)}
            required={false}
          />
        </Box>

        <Box>
          <BooleanInput
            source='is_certified'
            label={label('is_certified')}
            validate={getValidator(false)}
          />
          {
            isNewRecord &&
            <>
              <NumberInput
                {...formInputOptions}
                source='overtime_pay_rate_in_dollars'
                label={label('overtime_pay_rate_in_dollars')}
                disabled
              />
              <NumberInput
                {...formInputOptions}
                source='doubletime_pay_rate_in_dollars'
                label={label('doubletime_pay_rate_in_dollars')}
                disabled
              />
            </>
          }
        </Box>
      </Box>
    </Form>
  )
}

export default PayscalesForm
