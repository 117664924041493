import { useMemo } from 'react'
import groupBy from 'lodash/groupBy'
import { conditionallyVisibleFields } from './util'

const sortTickets = (a, b) => {
  // date asc
  if (a.worked_at_date > b.worked_at_date) return 1
  if (a.worked_at_date < b.worked_at_date) return -1

  // ticket_number asc
  if (a.ticket_number > b.ticket_number) return 1
  if (a.ticket_number < b.ticket_number) return -1
  return 0
}

const useHourlyInvoiceTickets = (tickets) => {
  return useMemo(() => {
    const sorted = tickets.sort(sortTickets)
    const grouped = groupBy(sorted, 'worked_at_date')

    const conditionallyVisibleFieldsValues = Object.values(conditionallyVisibleFields).flat()
    const conditionallyVisibleFieldsKeys = Object.keys(conditionallyVisibleFields)
    const conditionalFieldsToCheck = [...conditionallyVisibleFieldsKeys]
    const visibleFields = []

    const collection = Object.entries(grouped).reduce((accum, [date, tickets]) => {
      const sums = tickets.reduce((_accum, curr) => {

        for (const field of [...conditionalFieldsToCheck]) {
          if (curr[field]) {
            conditionalFieldsToCheck.splice(conditionalFieldsToCheck.indexOf(field), 1)
            conditionallyVisibleFields[field].forEach((f) => {
              visibleFields[conditionallyVisibleFieldsValues.indexOf(f)] = f
            })
          }
        }

        return {
          total_in_dollars: _accum.total_in_dollars + curr.total_in_dollars,
          regular_time_in_hours: _accum.regular_time_in_hours + curr.regular_time_in_hours,
          overtime_in_hours: _accum.overtime_in_hours + curr.overtime_in_hours,
          doubletime_in_hours: _accum.doubletime_in_hours + curr.doubletime_in_hours,
        }
      }, {
        total_in_dollars: 0,
        regular_time_in_hours: 0,
        overtime_in_hours: 0,
        doubletime_in_hours: 0,
      })

      return [
        ...accum,
        {
          data: tickets,
          subtotal: {
            ticket_number: tickets.length,
            worked_at_date: date,
            ...sums,
          },
        }
      ]
    }, [])

    return {
      collection,
      conditionallyVisibleFields: visibleFields.filter(Boolean),
    }
  }, [tickets])
}

export default useHourlyInvoiceTickets
export { sortTickets }
