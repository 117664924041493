import React from 'react'
import {
  TextField,
  TextInput,
  BooleanField,
  NullableBooleanInput,
} from 'react-admin'
import { booleanFieldOptions } from '../../util/component-options'
import { Datagrid, List, Filter } from '../../custom'
import { useLabel } from '../../../hooks'
import { makeStyles } from '@material-ui/core/styles'

const TrucksFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('number', 'filter')} source='number_i_cont' />
      <NullableBooleanInput label={label('is_active')} source='is_active_true' />
    </Filter>
  )
}

const useStyles = makeStyles(
  {
    booleanFieldHeader: {
      textAlign: 'center',
    },
  },
)

const TrucksList = props => {
  const label = useLabel(props)
  const classes = useStyles(props)

  return (
    <List
      {...props}
      filters={<TrucksFilter />}
      filterDefaultValues={{ is_active_true: true }}
    >
      <Datagrid>
        <TextField source='number' label={label('number')} />
        <TextField source='default_driver.name' sortBy='default_driver_name' label={label('default_driver')} />
        <BooleanField {...booleanFieldOptions} source='is_active' label={label('is_active')} headerClassName={classes.booleanFieldHeader} />
      </Datagrid>
    </List>
  )
}

export default TrucksList
