import {
  ReferenceInput,
  TextInput,
} from 'react-admin'
import { useLabel, useAddressInputFields, usePrevious } from '../../hooks'
import { Autocomplete } from '../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../util/component-options'
import { getValidator } from '../form'
import { useForm, useFormState } from 'react-final-form'
import { useEffect } from 'react'

const AddressInputFields = ({ prefix = 'address', resource, addressRequired = true, includeCounty }) => {
  const label = useLabel({ resource })
  const { change: formChange } = useForm()
  const formState = useFormState()
  const unitedStateId = formState.values[`${prefix}_united_state_id`]
  const prevUnitedStateId = usePrevious(unitedStateId)
  const addressFields = useAddressInputFields(prefix, includeCounty, unitedStateId)

  useEffect(() => {
    if (!includeCounty) return
    if (prevUnitedStateId != null && prevUnitedStateId !== unitedStateId) {
      formChange(`${prefix}_county_id`, undefined)
    }
  }, [prevUnitedStateId, unitedStateId, formChange, includeCounty, prefix])

  return (
    addressFields.map(({ source, required, label: _label, reference, filter, disabled }) => (
      reference ?
        <ReferenceInput
          {...referenceInputOptions(reference)}
          {...formInputOptions}
          key={source}
          label={label(_label)}
          source={source}
          reference={reference}
          validate={getValidator(addressRequired && required)}
          required={addressRequired && required}
          perPage={1000}
          filter={{
            ...referenceInputOptions(reference).filter,
            ...filter,
          }}
          disabled={disabled}
        >
          <Autocomplete/>
        </ReferenceInput> :

        <TextInput
          {...formInputOptions}
          key={source}
          validate={getValidator(addressRequired && required)}
          source={source}
          autoComplete='chrome-off'
          label={label(_label)}
          required={addressRequired && required}
        />
    ))
  )
}

export default AddressInputFields
