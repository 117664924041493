import { useCallback } from 'react'
import {
  TextInput,
  ImageInput,
  ImageField,
} from 'react-admin'
import { Box } from '@material-ui/core'
import {
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form } from '../../form'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { getValidator } from '../../form'
import { ImageWithModal } from '../../shared'

const StyledImageUploadField = withStyles(() => ({
  image: {
    maxHeight: 450,
    maxWidth: '100%',
    margin: 0,
  }
}))(ImageField)

const StyledImageInput = withStyles((theme) => ({
  root: {
    margin: 0,
  },
  preview: {
    display: 'inline-block',
    marginLeft: theme.spacing(4),
  },
  removeButton: {
    display: 'inline-block',
    position: 'relative',
    '& button': {
      position: 'absolute',
      top: theme.spacing(-1.5),
      left: theme.spacing(-2),
      minWidth: theme.spacing(2),
    },
  },
}))(ImageInput)

const useStyles = makeStyles(() => ({
  image: {
    maxHeight: 380,
    maxWidth: '100%',
  },
}))

const TonnageTicketUploadsForm = props => {
  const formClasses = useFormStyles(props)
  const label = useLabel(props)
  const { record, isUpdateDisabled } = props
  const classes = useStyles()

  const transform = useCallback(data => {
    return {
      id: data.id,
      ticket_image: data.imageUpload?.rawFile,
    }
  }, [])

  return (
    <Form
      {...props}
      transform={transform}
    >
      <Box className={formClasses.grid}>
        {
          (record.ticket_image_filename || record.ticket_image_url) &&
            <Box>
              <TextInput
                {...formInputOptions}
                source='ticket_image_filename'
                label={label('ticket_image_filename')}
                disabled
              />
              {
                record.sender &&
                  <TextInput
                    {...formInputOptions}
                    source='sender.phone'
                    label={label('sender')}
                    disabled
                  />
              }
              <ImageWithModal
                className={classes.image}
                src={record.ticket_image_url}
              />
            </Box>
        }
        {
          !isUpdateDisabled &&
          <Box>
            <StyledImageInput
              source='imageUpload'
              label={''}
              accept='image/*'
              validate={getValidator()}
              required
            >
              <StyledImageUploadField
                source='src'
                title='title'
              />
            </StyledImageInput>
          </Box>
        }
      </Box>
    </Form>
  )
}

export default TonnageTicketUploadsForm
