import React from 'react'
import {
  TextField,
  TextInput,
  NumberField,
} from 'react-admin'
import { Datagrid, List, Filter } from '../../custom'
import { useLabel } from '../../../hooks'
import {
  currencyFieldOptions,
} from '../../util/component-options'

const ZoneRatesFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('zone_1', 'filter')} source='zone_1_name_i_cont' />
      <TextInput label={label('zone_2', 'filter')} source='zone_2_name_i_cont' />
    </Filter>
  )
}

const MaterialSalePricesList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<ZoneRatesFilter />}
    >
      <Datagrid>
        <TextField source='zone_1.name' sortBy='zone_1_name' label={label('zone_1')} />
        <TextField source='zone_2.name' sortBy='zone_2_name' label={label('zone_2')} />
        <NumberField source='rate_in_dollars' label={label('rate_in_dollars')} options={currencyFieldOptions}/>
      </Datagrid>
    </List>
  )
}

export default MaterialSalePricesList
