import {
  cloneElement,
} from 'react'
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  useQueryWithStore,
  useTranslate,
} from 'react-admin'
import classnames from 'classnames'
import PrintIcon from '@material-ui/icons/Print'
import usePrint from '../../hooks/use-print'

const useStyles = makeStyles(theme => {
  return {
    container: {
      marginRight: theme.spacing(-1.75),
      marginLeft: theme.spacing(-1.75),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    invoiceCol: {
      flexGrow: 1,
    },
  }
})

const Loading = () => {
  const translate = useTranslate()

  return (
    <Typography
      variant='body1'
    >
      {`${translate('ra.page.loading')}...`}
    </Typography>
  )
}

const GridExpand = ({ children, className, resource, record, payload = {}, printOptions = {} }) => {
  const { data: detail, loaded, loading } = useQueryWithStore({
    type: 'getOne',
    resource,
    payload: {
      ...payload,
      id: record.id,
    },
  })

  const [
    print,
    Printable,
    willPrint,
  ] = usePrint(printOptions)

  const classes = useStyles()

  return (
    <Grid container spacing={1} wrap='nowrap' className={classes.container}>
      <Grid item>
        <IconButton
          size='small'
          color='secondary'
          onClick={print}
          disabled={willPrint}
        >
          <PrintIcon />
        </IconButton>
      </Grid>
      <Grid item className={classnames(classes.invoiceCol, className)}>
        <Card>
          <CardContent>
            {
              !loaded && loading ?
                <Loading /> :
                <Printable renderChildren>
                  {cloneElement(children, { record: detail })}
                </Printable>
            }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default GridExpand
