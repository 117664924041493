import React from 'react'
import {
  ReferenceInput,
  TextInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { Autocomplete } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { relationships } from '../../../data-model'
import { Form, getValidator } from '../../form'
import { AddressInputFields } from '../../shared'

const SitesForm = props => {
  const { resource } = props
  const resourceRelationships = relationships[resource]
  const formClasses = useFormStyles(props)
  const label = useLabel(props)

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <TextInput
            {...formInputOptions}
            source='name'
            label={label('name')}
            validate={getValidator()}
            required
          />

          <ReferenceInput
            {...referenceInputOptions(resourceRelationships['zone'])}
            {...formInputOptions}
            source='zone.id'
            reference={resourceRelationships['zone']}
            label={label('zone')}
          >
            <Autocomplete validate={getValidator(false)} />
          </ReferenceInput>

          <ReferenceInput
            {...referenceInputOptions(resourceRelationships['company'])}
            {...formInputOptions}
            source='company.id'
            reference={resourceRelationships['company']}
            label={label('company')}
          >
            <Autocomplete validate={getValidator(false)} />
          </ReferenceInput>
        </Box>
        <Box>
          <AddressInputFields
            resource={resource}
            addressRequired={false}
          />
        </Box>
      </Box>
    </Form>
  )
}

export default SitesForm
