import {
  Grid,
  Box,
  makeStyles,
} from '@material-ui/core'
import { dateFieldOptions } from '../../util/component-options'

const formatFloat = (num, digits = 0) => {
  return (num === null ? '-' : num.toLocaleString([], {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }))
}

const deductionFields = [
  {
    label: 'FICA',
    source: 'fica',
  },
  {
    label: 'Federal',
    source: 'federal',
  },
  {
    label: 'State',
    source: 'state',
  },
  {
    label: 'County',
    source: 'county',
  },
  {
    label: 'Other',
    source: 'other',
  },
  {
    label: 'Total',
    source: 'total',
  },
]

const useStyles = makeStyles(theme => {
  const tableCell = {
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: '0px 1px',
    textAlign: 'center'
  }

  const table = {
    width: '100%',
    height: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    fontSize: 10,
    '& th': tableCell,
    '& td': tableCell,
  }

  return {
    table: {
      marginTop: 20,
      ...table,
      '& table': {
        ...table,
        '& th:first-child': {
          borderLeftWidth: 0,
        },
        '& th:last-child': {
          borderRightWidth: 0,
        },
        '& td:last-child': {
          borderRightWidth: 0,
        },
        '& td:first-child': {
          borderLeftWidth: 0,
        },
        '& tr:first-child': {
          '& th': {
            borderTopWidth: 0,
          },
          '& td': {
            borderTopWidth: 0,
          },
        },
        '& tr:last-child': {
          '& th': {
            borderBottomWidth: 0,
          },
          '& td': {
            borderBottomWidth: 0,
          },
        },
      },
    },
    row: {
      '& > div': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: 0,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        padding: theme.spacing(0.3),
      },
      '& > div:last-child': {
        borderRightWidth: 0,
      },
    },
    label: {
      fontSize: 11
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
  }
})

const Report = ({ record } ) => {
  const {
    customer_name,
    project_name,
    certified_location,
    hours_by_day,
    employee_driver_pay_rollups,
    tenant,
    work_class_name,
  } = record
  const weekEndingDate = new Date([...hours_by_day].pop().date)
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Grid container className={classes.row}>
        <Grid item xs={6}>
          <div className={classes.label}>
            Name of contractor or subcontractor
          </div>
          <div>
            { tenant.name }
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.label}>
            Address
          </div>
          <div>
            {`${tenant.address.street_1}, ${tenant.address.city}, ${tenant.address.united_state_abbreviation} ${tenant.address.zip}`}
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.row}>
        <Grid item xs={3}>
          <div className={classes.label}>
            Contractor
          </div>
          <div>
            { customer_name }
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.label}>
            For week ending
          </div>
          <div>
            {weekEndingDate.toLocaleString([], dateFieldOptions)}
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.label}>
            Project and location
          </div>
          <div>
            { `${project_name} - ${certified_location}` }
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.label}>
            Project or contract no.
          </div>
          <div>
            {project_name}
          </div>
        </Grid>
      </Grid>

      <table className={classes.table}>
        <thead>
          <tr>
            <th style={{ width: '24%' }}>
              <div>
                Name
              </div>
              <div>
                Address
              </div>
              <div>
                SS#
              </div>
            </th>
            <th>
              Work class
            </th>

            <th style={{ width: '2%' }}>
              <table>
                <tbody>
                  {
                    ['DT', 'OT', 'ST'].map(t => (
                      <tr key={t}>
                        <th>
                          {t}
                        </th>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </th>

            <th colSpan={hours_by_day.length} style={{ width: '21%' }}>
              <table>
                <tbody>
                  <tr>
                    <th colSpan={hours_by_day.length}>
                      Day and date
                    </th>
                  </tr>

                  <tr>
                    {
                      hours_by_day.map(d => {
                        return (
                          <th key={d.day_of_week}>
                            {(new Date(d.date)).toLocaleString([], { timeZone: 'UTC', weekday: 'short' })[0]}
                          </th>
                        )
                      })
                    }
                  </tr>

                  <tr>
                    {
                      hours_by_day.map(d => {
                        return (
                          <th key={d.day_of_week}>
                            {new Date(d.date).toLocaleString([], { timeZone: 'UTC', day: 'numeric' })}
                          </th>
                        )
                      })
                    }
                  </tr>

                  <tr>
                    <th colSpan={hours_by_day.length}>
                      Hours worked each day
                    </th>
                  </tr>
                </tbody>
              </table>
            </th>

            <th>
              Total hours
            </th>
            <th>
              Rate of pay
            </th>
            <th>
              Certified gross
            </th>
            <th>
              Gross earned
            </th>

            <th colSpan={deductionFields.length}>
              <table>
                <tbody>
                  <tr>
                    <th colSpan={deductionFields.length}>
                      Deductions
                    </th>
                  </tr>
                  <tr>
                    {
                      deductionFields.map(({ label }) => (
                        <th key={label}>
                          {label}
                        </th>
                      ))
                    }
                  </tr>
                </tbody>
              </table>
            </th>

            <th>
              Net wages
            </th>
          </tr>
        </thead>

        <tbody>
          {
            employee_driver_pay_rollups.map(rollup => {
              const { driver_id, address, name, ssn, withholdings } = rollup

              return (
                <tr key={driver_id}>
                  <td>
                    <div>
                      {name}
                    </div>
                    <div>
                      {`${address.street_1}, ${address.city}, ${address.united_state_abbreviation} ${address.zip}`}
                    </div>
                    <div>
                      {ssn}
                    </div>
                  </td>
                  <td>
                    {work_class_name}
                  </td>

                  <td>
                    <table>
                      <tbody>
                        {
                          ['DT', 'OT', 'ST'].map(t => (
                            <tr key={t}>
                              <td>
                                {t}
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </td>

                  <td colSpan={hours_by_day.length}>
                    <table>
                      <tbody>
                        {
                          ['dt_hours', 'ot_hours', 'reg_hours'].map(t => {
                            return (
                              <tr key={t}>
                                {
                                  hours_by_day.map(d => {
                                    const hoursWorked = d.hours_worked.find(h => h.driver_id === driver_id)

                                    return (
                                      <td key={d.day_of_week}>
                                        {formatFloat(hoursWorked?.[t] ?? 0, 1)}
                                      </td>
                                    )
                                  })
                                }
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </td>

                  <td>
                    <table>
                      <tbody>
                        {
                          ['certified_doubletime_in_hours', 'certified_overtime_in_hours', 'certified_regular_time_in_hours'].map(t => (
                            <tr key={t}>
                              <td>
                                {formatFloat(rollup[t], 1)}
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </td>

                  <td>
                    <table>
                      <tbody>
                        {
                          ['certified_doubletime_pay_rate_in_dollars', 'certified_overtime_pay_rate_in_dollars', 'certified_regular_pay_rate_in_dollars'].map(t => (
                            <tr key={t}>
                              <td>
                                {formatFloat(rollup[t], 2)}
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </td>

                  {
                    ['gross_certified_earned_in_dollars', 'gross_earned_in_dollars'].map(t => (
                      <td key={t}>
                        {formatFloat(rollup[t])}
                      </td>
                    ))
                  }

                  {
                    deductionFields.map(({ source }) => (
                      <td key={source}>
                        {formatFloat(withholdings[source])}
                      </td>
                    ))
                  }

                  {
                    <td>
                      {formatFloat(rollup.net_pay_in_dollars)}
                    </td>
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </Box>
  )
}

export default Report
