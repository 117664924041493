import {
  TextField,
  NumberField,
  DateField,
  TextInput,
} from 'react-admin'
import { Datagrid, List, Filter } from '../../custom'
import {
  dateTimeFieldOptions,
} from '../../util/component-options'
import { useLabel } from '../../../hooks'

const TonnageTicketPdfUploadsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('pdf_filename', 'find')} source='pdf_file_blob_filename_i_cont' />
    </Filter>
  )
}

const TonnageTicketPdfUploadsList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<TonnageTicketPdfUploadsFilter />}
    >
      <Datagrid
        hasEdit
      >
        <TextField source='pdf_filename' label={label('pdf_filename')}/>
        <NumberField source='page_count' label={label('page_count')}/>
        <NumberField source='tonnage_ticket_uploads_count' label={label('tonnage_ticket_uploads_count')}/>
        <DateField source='created_at' label={label('created_at')} options={dateTimeFieldOptions}/>
      </Datagrid>
    </List>
  )
}

export default TonnageTicketPdfUploadsList
