import { useCallback } from 'react'
import {
  Loading,
  TextField,
  NumberField,
  TextInput,
  NullableBooleanInput,
  DateField,
} from 'react-admin'
import { Datagrid, List, Filter } from '../../custom'
import {
  numberFieldOptions,
  dateFieldOptions,
  currencyFieldOptions,
} from '../../util/component-options'
import { useEarliestIncompletePeriod, useLabel } from '../../../hooks'
import PeriodDatePicker from '../../shared/period-date-picker'

const TimesheetsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <PeriodDatePicker label={label('period_end_date')} source='period_end_date_eq' />
      <NullableBooleanInput label={label('is_archived')} source='is_archived_true' />
      <TextInput label={label('driver', 'filter')} source='driver_name_i_cont' />
    </Filter>
  )
}

const TimesheetsList = props => {
  const label = useLabel(props)

  const hasEdit = useCallback(record => {
    return !record.is_archived
  }, [])

  const hasShow = useCallback(record => {
    return !hasEdit(record)
  }, [hasEdit])

  const period = useEarliestIncompletePeriod()

  if (!period) return <Loading />

  return (
    <List
      {...props}
      filters={<TimesheetsFilter />}
      filterDefaultValues={{ period_end_date_eq: period?.end_date }}
    >
      <Datagrid
        hasEdit={hasEdit}
        hasShow={hasShow}
      >
        <TextField source='driver.name' label={label('driver')} sortBy='driver_name' />
        <NumberField source='num_days_filled' label={label('num_days_filled')} />
        <NumberField source='total_time_in_hours' label={label('total_time_in_hours')} options={numberFieldOptions} />
        <NumberField source='minimum_hourly_wage_in_dollars' label={label('minimum_hourly_wage_in_dollars')} options={currencyFieldOptions} />
        <DateField source='period.end_date' label={label('period_end_date')} options={dateFieldOptions} sortBy='period_end_date'/>
      </Datagrid>
    </List>
  )
}

export default TimesheetsList
