import React from 'react'
import {
  TextField,
  TextInput,
  NumberField,
  BooleanField,
} from 'react-admin'
import { Datagrid, List, Filter } from '../../custom'
import { makeStyles } from '@material-ui/core/styles'
import {
  currencyFieldOptions,
  booleanFieldOptions,
} from '../../util/component-options'
import { useLabel } from '../../../hooks'

const useStyles = makeStyles(
  {
    booleanFieldHeader: {
      textAlign: 'center',
    },
  },
)

const PayscalesFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('type_of_payscale', 'filter')} source='type_of_payscale_i_cont' />
    </Filter>
  )
}

const PayscalesList = props => {
  const classes = useStyles(props)
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<PayscalesFilter />}
    >
      <Datagrid>
        <TextField source='code' label={label('code')} />
        <TextField source='type_of_payscale' label={label('type_of_payscale')} />
        <NumberField source='regular_pay_rate_in_dollars' label={label('regular_pay_rate_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='overtime_pay_rate_in_dollars' label={label('overtime_pay_rate_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='doubletime_pay_rate_in_dollars' label={label('doubletime_pay_rate_in_dollars')} options={currencyFieldOptions} />
        <BooleanField {...booleanFieldOptions} source='is_certified' label={label('is_certified')} headerClassName={classes.booleanFieldHeader} />
      </Datagrid>
    </List>
  )
}

export default PayscalesList
