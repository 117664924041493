import { useTranslate } from 'react-admin'
import { Grid, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ImageWithModal from '../shared/image-with-modal'

const useStyles = makeStyles(({ spacing }) => {
  return {
    container: {
      alignItems: 'stretch',
      padding: spacing(4),
    },
    childGrid: {
      flexBasis: `50%`,
      flexGrow: 1,
      margin: -spacing(4), // hacky assumption - undo form styling
    },
    imageGrid: {
      flexBasis: '50%',
      flexGrow: 1,
      flexShrink: 1,
      display: 'flex',
    },
    image: {
      maxWidth: '100%',
      maxHeight: 800,
      position: 'sticky',
      top: 50,
    },
    senderWrapper: {
      marginBottom: spacing(2),
    },
    senderLabel: {
      marginRight: spacing(1.5),
    },
  }
})

const withTicketImage = (WrappedComponent) => (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  const { imageUrl, senderPhone, ...rest } = props

  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item className={classes.childGrid}>
        <WrappedComponent {...rest} />
      </Grid>
      {
        imageUrl &&
        <Grid item className={classes.imageGrid}>
          <Box className={classes.uploadWrapper}>
            {
              senderPhone &&
                <Box className={classes.senderWrapper}>
                  <Typography className={classes.senderLabel} variant='h6' component='span'>
                    {`${translate('uploadedTicket.sender')}:`}
                  </Typography>
                  <Typography variant='h6' component='span'>
                    {senderPhone}
                  </Typography>
                </Box>
              }
            <ImageWithModal
              className={classes.image}
              src={imageUrl}
            />
          </Box>
        </Grid>
      }
    </Grid>
  )
}

export default withTicketImage
