import {
  TextInput,
  BooleanInput,
  RadioButtonGroupInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import {
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form, getValidator } from '../../form'
import { AddressInputFields } from '../../shared'
import { tonnageInvoiceGroupingChoices } from './util'

const CompaniesForm = props => {
  const { resource } = props
  const formClasses = useFormStyles(props)
  const label = useLabel(props)

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <TextInput
            {...formInputOptions}
            source='name'
            label={label('name')}
            validate={getValidator()}
            required
          />

          <TextInput
            {...formInputOptions}
            source='email'
            label={label('email')}
            validate={getValidator(false)}
          />

          <TextInput
            {...formInputOptions}
            source='phone_number'
            label={label('phone_number')}
            validate={getValidator(false)}
          />

          <TextInput
            {...formInputOptions}
            source='ledger_code'
            label={label('ledger_code')}
            validate={getValidator(false)}
          />

          <RadioButtonGroupInput
            source='tonnage_invoice_grouping'
            defaultValue={tonnageInvoiceGroupingChoices[0].id}
            helperText={false}
            choices={tonnageInvoiceGroupingChoices}
          />

          <BooleanInput
            source='is_exempt_from_sales_tax'
            label={label('is_exempt_from_sales_tax')}
            defaultValue={false}
          />

        </Box>

        <Box>
          <AddressInputFields resource={resource} addressRequired={false} prefix='billing_address' />
        </Box>
      </Box>
    </Form>
  )
}

export default CompaniesForm
