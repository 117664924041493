import { useMemo } from 'react'
import {
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import { Box, Grid, makeStyles } from '@material-ui/core'
import {
  formInputOptions,
  referenceInputOptions,
  dateFieldOptions,
} from '../../util/component-options'
import { Autocomplete, BooleanInput, NumberInput } from '../../custom'
import { useFormStyles } from '../../../styles'
import { useLabel, useEarliestIncompletePeriod } from '../../../hooks'
import { Form, getValidator } from '../../form'
import { relationships } from '../../../data-model'
import { dateOnlyStringToJSDate } from '../../../util/date'

const validate = getValidator()

const useStyles = makeStyles(({ spacing }) => {
  return {
    text: {
      flex: 1,
    },
    boolean: {
      marginLeft: spacing(2),
    },
  }
})

const TimesheetsForm = props => {
  const { resource, record } = props
  const formClasses = useFormStyles(props)
  const classes = useStyles()
  const label = useLabel(props)
  const resourceRelationships = relationships[resource]
  const isDisabled = Boolean(record.is_archived)
  const defaultPeriod = useEarliestIncompletePeriod()
  const { period: recordPeriod } = record

  const period = useMemo(() => {
    return recordPeriod ?? defaultPeriod
  }, [defaultPeriod, recordPeriod])

  const dayFields = useMemo(() => {
    const result = []
    const holidayLabel = label('is_holiday')

    const periodEndDate = period?.end_date ? dateOnlyStringToJSDate(period.end_date) : null
    for (let i = 6; i >= 0; i--) {
      const timeLabel = periodEndDate ? (function() {
        const date = new Date(periodEndDate)
        date.setDate(date.getDate() - i)
        return date.toLocaleString([], {
          ...dateFieldOptions,
          weekday: 'short',
        })
      })() : ''

      const dayOfWeek = 7 - i

      const timeSource = `day_${dayOfWeek}_time_in_hours`
      const holidaySource = `day_${dayOfWeek}_is_holiday`

      result.push({
        timeLabel,
        timeSource,
        holidaySource,
        holidayLabel,
      })
    }

    return result
  }, [period, label])

  const periodChoices = useMemo(() => {
    if (period) {
      return [period]
    } else {
      return []
    }
  }, [period])

  return (
    <Form
      {...props}
      isUpdateDisabled={isDisabled}
      isDeleteDisabled={isDisabled}
    >
      <Box className={formClasses.grid}>
        <Box>
          <SelectInput
            {...formInputOptions}
            source='period.id'
            disabled
            choices={periodChoices}
            defaultValue={defaultPeriod?.id}
            label={label('period')}
            optionText={(opt) => {
              return new Date(opt.end_date).toLocaleString([], dateFieldOptions)
            }}
          />

          <ReferenceInput
            {...formInputOptions}
            {...referenceInputOptions(resourceRelationships['driver'])}
            source='driver.id'
            reference={resourceRelationships['driver']}
            label={label('driver')}
            required
            filter={{
              ...referenceInputOptions(resourceRelationships['driver']).filter,
              driverable_type_eq: 'EmployeeDriver',
            }}
            autoFocus
          >
            <Autocomplete
              validate={validate}
            />
          </ReferenceInput>
        </Box>
        <Box>
          {
            dayFields.map((f, idx) => {
              return (
                <Grid container className={classes.container} key={idx}>
                  <Grid item className={classes.text}>
                    <NumberInput
                      {...formInputOptions}
                      source={f.timeSource}
                      label={f.timeLabel}
                      step={0.01}
                      min={0}
                    />
                  </Grid>
                  <Grid item className={classes.boolean}>
                    <BooleanInput
                      tabIndex='-1'
                      source={f.holidaySource}
                      label={f.holidayLabel}
                    />
                  </Grid>
                </Grid>
              )
            })
          }
        </Box>
      </Box>
    </Form>
  )
}

export default TimesheetsForm
