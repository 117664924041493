import React from 'react'
import {
  TextField,
  NumberField,
  TextInput,
  BooleanField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Datagrid, List, Filter } from '../../custom'
import {
  currencyFieldOptions,
  booleanFieldOptions,
} from '../../util/component-options'
import { useLabel } from '../../../hooks'

const useStyles = makeStyles(
  {
    booleanFieldHeader: {
      textAlign: 'center',
    },
  },
)

const HourlyRatesFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('code', 'filter')} source='code_i_cont' />
      <TextInput label={label('description', 'filter')} source='description_i_cont' />
      <TextInput label={label('customer', 'filter')} source='customer_name_i_cont' />
      <TextInput label={label('payscale', 'filter')} source='payscale_type_of_payscale_i_cont' />
    </Filter>
  )
}

const HourlyRatesList = props => {
  const classes = useStyles(props)
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<HourlyRatesFilter />}
    >
      <Datagrid>
        <TextField source='code' label={label('code')} />
        <TextField source='description' label={label('description')} />
        <TextField source='customer.name' sortBy='customer_name' label={label('customer')} />
        <TextField source='certified_location' label={label('certified_location')} />
        <NumberField source='regular_rate_in_dollars' label={label('regular_rate_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='overtime_rate_in_dollars' label={label('overtime_rate_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='doubletime_rate_in_dollars' label={label('doubletime_rate_in_dollars')} options={currencyFieldOptions} />
        <TextField source='payscale.type_of_payscale' sortBy='payscale_type_of_payscale' label={label('payscale')} />
        <BooleanField {...booleanFieldOptions} source='payscale.is_certified' label={label('is_certified')} headerClassName={classes.booleanFieldHeader} />
      </Datagrid>
    </List>
  )
}

export default HourlyRatesList
