import {
  TextInput,
  TextField,
  NumberField,
} from 'react-admin'
import { List, Filter, Datagrid, DateInput } from '../../custom'
import { useLabel } from '../../../hooks'
import {
  currencyFieldOptions,
} from '../../util/component-options'

const BrokerDriverAggregateProfitsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <DateInput label={label('ticket_date', 'earliest')} source='ticket_date_gteq' />
      <DateInput label={label('ticket_date', 'latest')} source='ticket_date_lteq' />
      <TextInput label={label('name', 'filter')} source='name_i_cont' />
    </Filter>
  )
}

const BrokerDriverAggregateProfitsList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<BrokerDriverAggregateProfitsFilter />}
    >
      <Datagrid
        hasEdit={false}
      >
        <TextField source='name' label={label('name')} />
        <NumberField source='sum_regular_total_in_dollars' label={label('sum_regular_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_overtime_total_in_dollars' label={label('sum_overtime_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_doubletime_total_in_dollars' label={label('sum_doubletime_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_total_in_dollars' label={label('sum_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_tonnage_income_total_in_dollars' label={label('sum_tonnage_income_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_hourly_income_total_in_dollars' label={label('sum_hourly_income_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_income_total_in_dollars' label={label('sum_income_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_profit_total_in_dollars' label={label('sum_profit_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_total_line_item_quantity' label={label('sum_total_line_item_quantity')} />
      </Datagrid>
    </List>
  )
}

export default BrokerDriverAggregateProfitsList
