import {
  TextField,
  FunctionField,
  useListContext,
} from 'react-admin'
import { Datagrid } from '../../custom'
import Report from './report'
import { CertifiedPayrollReportGridExpand } from '../../shared'
import { useLabel } from '../../../hooks'

const Expand = ({ record }) => (
  <CertifiedPayrollReportGridExpand>
    <Report record={record} />
  </CertifiedPayrollReportGridExpand>
)

const CertifiedPayrollReportsDatagrid = ({ isPrintView }) => {
  const context = useListContext()
  const label = useLabel(context)

  return (
    <Datagrid
      hasEdit={false}
      expand={isPrintView ? undefined : <Expand />}
    >
      <TextField source='customer_name' label={label('customer_name')} sortable={false} />
      <TextField source='certified_location' label={label('certified_location')} sortable={false} />
      <FunctionField label={label('driver_pay_rollups_count')} render={record => {
        return record.employee_driver_pay_rollups.length
      }} />
    </Datagrid>
  )
}

export default CertifiedPayrollReportsDatagrid
