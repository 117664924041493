import React from 'react'
import {
  TextField,
  TextInput,
  NumberField,
} from 'react-admin'
import { Datagrid, List, Filter } from '../../custom'
import { useLabel } from '../../../hooks'
import {
  currencyFieldOptions,
} from '../../util/component-options'

const MaterialSalePricesFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('material', 'filter')} source='material_name_i_cont' />
      <TextInput label={label('pickup_site', 'filter')} source='pickup_site_name_i_cont' />
      <TextInput label={label('name', 'filter')} source='name_i_cont' />
    </Filter>
  )
}

const MaterialSalePricesList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<MaterialSalePricesFilter />}
    >
      <Datagrid>
        <TextField source='material.name' sortBy='material_name' label={label('material')} />
        <TextField source='pickup_site.name' sortBy='pickup_site_name' label={label('pickup_site')} />
        <NumberField source='price_in_dollars' label={label('price_in_dollars')} options={currencyFieldOptions}/>
        <TextField source='name' label={label('name')} />
      </Datagrid>
    </List>
  )
}

export default MaterialSalePricesList
