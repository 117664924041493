import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  ReferenceInput,
  TextInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import get from 'lodash/get'
import { Autocomplete, DateInput, NumberInput } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import {
  useLabel,
  useTranslateResource,
} from '../../../hooks'
import { relationships } from '../../../data-model'
import { Form, TruckDriverFields, getValidator } from '../../form'
import {
  useHourlyRateFields,
  useHourlyRateReferenceFields,
  useHourlyTicketTimeFields,
  useReferenceLookup,
  useAutofillFields,
} from '../../form/functions'

const validate = getValidator()

const hourlyRateReferenceInputOptions = {
  sort: {
    field: 'code',
    order: 'ASC',
  },
}

const skipTabInputProps = {
  tabIndex: '-1',
}

const FormBody = props => {
  const { resource, record } = props
  const isPending = resource === 'pending_hourly_tickets'
  const isNewRecord = !record.id || isPending
  const resourceRelationships = relationships[resource]
  const formClasses = useFormStyles(props)
  const rateFields = useHourlyRateFields()
  const rateReferenceFields = useHourlyRateReferenceFields()
  const timeFields = useHourlyTicketTimeFields()
  const label = useLabel(props)
  const translate = useTranslateResource(resource, 'form')
  const { selectedRecord: selectedRate, onSelectedIdChange: onSelectedRateIdChange } = useReferenceLookup(resourceRelationships['hourly_rate'], record.hourly_rate)

  const autofillRateReferenceFields = useMemo(() => {
    return rateReferenceFields.map(f => `${f.source}.id`)
  }, [rateReferenceFields])

  const rateReferenceFieldValueKeys = useMemo(() => {
    return rateReferenceFields.reduce((accum, f) => {
      return {
        ...accum,
        [f.source]: f.optionText,
      }
    }, {})
  }, [rateReferenceFields])

  const autofillRateFields = useMemo(() => {
    return rateFields.map(f => f.source)
  }, [rateFields])

  const shouldAutofillRateReferenceField = useCallback(() => {
    return isNewRecord
  }, [isNewRecord])

  const shouldAutofillRateField = useCallback(() => {
    return isNewRecord
  }, [isNewRecord])

  const shouldAutofillDriverField = useCallback(() => {
    return isNewRecord
  }, [isNewRecord])

  const [
    getRateReferenceFieldIsAutofilled,
    onRateReferenceFieldChange,
  ] = useAutofillFields(autofillRateReferenceFields, selectedRate, shouldAutofillRateReferenceField)

  const [
    getRateFieldIsAutofilled,
    onAutofillRateFieldChange,
  ] = useAutofillFields(autofillRateFields, selectedRate, shouldAutofillRateField)

  const rate = selectedRate

  const getRateFieldHelperText = useCallback(field => {
    const value = get(rate, field) ?? 'blank'
    const autofilled = getRateFieldIsAutofilled(field)

    if (isNewRecord) {
      if (rate) {
        if (autofilled) {
          return translate('general.autofilledValueFromRate', { value })
        } else {
          return translate('general.currentRateValue', { value })
        }
      } else {
        return translate('general.noRate')
      }
    } else {
      if (rate) {
        return translate('general.currentRateValue', { value })
      }
    }
  }, [rate, isNewRecord, translate, getRateFieldIsAutofilled])

  const selectedRateText = useMemo(() => {
    if (rate) {
      return translate('general.selectedRate', {
        description: rate.description,
        code: rate.code,
      })
    }
  }, [rate, translate])

  const getRateReferenceFieldHelperText = useCallback(field => {
    if (!rate) return
    const valueKey = rateReferenceFieldValueKeys[field]
    const value = get(rate, [field, valueKey])
    if (!value) return
    const autofilled = getRateReferenceFieldIsAutofilled(`${field}.id`)
    return translate(`general.${autofilled ? 'autofilledValueFromRate' : 'currentRateValue'}`, { value })
  }, [rate, getRateReferenceFieldIsAutofilled, rateReferenceFieldValueKeys, translate])

  return (
    <Box>
      <Box className={formClasses.grid}>
        <Box>
          <ReferenceInput
            {...formInputOptions}
            {...referenceInputOptions(resourceRelationships['hourly_rate'])}
            {...hourlyRateReferenceInputOptions}
            source='hourly_rate.id'
            reference={resourceRelationships['hourly_rate']}
            label={translate('labels.hourlyRate')}
            onChange={onSelectedRateIdChange}
            validate={validate}
            required
          >
            <Autocomplete
              optionText='code'
              optionSubtext='description'
              helperText={selectedRateText}
              matchFrom='start'
              autoFocus
            />
          </ReferenceInput>

          <TextInput
            {...formInputOptions}
            source='ticket_number'
            label={label('ticket_number')}
            validate={validate}
            required
          />

          <DateInput
            {...formInputOptions}
            validate={validate}
            source='worked_at_date'
            label={label('worked_at_date')}
            required
          />

          {
            timeFields.map(({ source, required, label: _label }) => {
              return (
                <NumberInput
                  {...formInputOptions}
                  key={source}
                  validate={getValidator(required)}
                  source={source}
                  label={label(_label)}
                  step={0.01}
                  min={0}
                  required={required}
                />
              )
            })
          }
        </Box>

        <Box>
          <ReferenceInput
            {...formInputOptions}
            {...referenceInputOptions(resourceRelationships['customer'])}
            source={'customer.id'}
            reference={resourceRelationships['customer']}
            label={label('customer')}
            onChange={onRateReferenceFieldChange.bind(null, 'customer.id')}
            required={true}
            inputProps={{
              ...formInputOptions.inputProps,
              ...(getRateReferenceFieldIsAutofilled('customer.id') ? skipTabInputProps : undefined),
            }}
          >
            <Autocomplete
              validate={getValidator(true)}
              helperText={getRateReferenceFieldHelperText('customer')}
              optionText={'name'}
              optionSubtext={'code'}
            />
          </ReferenceInput>

          <TruckDriverFields
            {...props}
            shouldAutofill={shouldAutofillDriverField}
          />
        </Box>

        <Box>
          {
            rateFields.map(({ source, required, label: _label }) => {
              return (
                <NumberInput
                  {...formInputOptions}
                  key={source}
                  validate={validate}
                  source={source}
                  label={label(_label)}
                  step={0.01}
                  min={0}
                  required={required}
                  onChange={onAutofillRateFieldChange.bind(null, source)}
                  helperText={getRateFieldHelperText(source)}
                  inputProps={{
                    ...formInputOptions.inputProps,
                    ...(getRateFieldIsAutofilled(source) ? skipTabInputProps : undefined),
                  }}
                />
              )
            })
          }
          <NumberInput
            {...formInputOptions}
            validate={getValidator(false)}
            source='fees_in_dollars'
            label={label('fees_in_dollars')}
            step={0.01}
            min={0}
          />

          <TextInput
            {...formInputOptions}
            source='description'
            label={label('description')}
            validate={getValidator(false)}
          />
        </Box>
      </Box>
    </Box>
  )
}

// wrap component so we can use useForm hook
const HourlyTicketsForm = props => {
  const { resource, record, useOptimisticSave } = props
  const translate = useTranslateResource(resource, 'form')
  const isDisabled = Boolean(record.snapshot_mode)

  const getOptimisticFailureMessageContext = useCallback((data) => {
    return `#${data.ticket_number}`
  }, [])

  return (
    <Form
      {...props}
      isUpdateDisabled={isDisabled}
      isDeleteDisabled={isDisabled}
      infoText={translate('info.formHelper')}
      useOptimisticSave={useOptimisticSave}
      getOptimisticFailureMessageContext={getOptimisticFailureMessageContext}
    >
      <FormBody
        {...props}
      />
    </Form>
  )
}

export default HourlyTicketsForm
