import {
  TextField,
  NumberField,
  DateField,
  useListContext,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import Invoice from './invoice'
import { GridExpand } from '../../shared'
import { Datagrid } from '../../custom'
import {
  currencyFieldOptions,
  dateFieldOptions,
  numberFieldOptions,
} from '../../util/component-options'
import { useLabel } from '../../../hooks'

const useStyles = makeStyles(() => {
  return {
    invoice: {
      maxWidth: 900,
    },
  }
})

const Expand = props => {
  const classes = useStyles()
  const { showDetail, ...rest } = props

  return (
    <GridExpand {...rest} className={classes.invoice}>
      <Invoice showDetail={showDetail} />
    </GridExpand>
  )
}

const TonnageTicketInvoicesDatagrid = ({ isPrintView, showDetail }) => {
  const context = useListContext()
  const label = useLabel(context)

  return (
    <Datagrid
      hasEdit={false}
      expand={isPrintView ? undefined : <Expand showDetail={showDetail}/>}
    >
      <TextField source='invoice_number' label={label('invoice_number')} />
      <TextField source='customer.name' label={label('customer')} sortBy='customer_name' />
      <TextField source='tonnage_rate.description' label={label('tonnage_rate')} sortBy='tonnage_rate_description' />
      <NumberField source='tickets_count' label={label('tickets_count')} />
      <NumberField source='units_count' label={label('units_count')} options={numberFieldOptions} />
      {/* <NumberField source='total_in_dollars' label={label('total_in_dollars')} options={currencyFieldOptions} /> */}
      <NumberField source='sales_tax_in_dollars' label={label('sales_tax_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='total_with_sales_tax_in_dollars' label={label('total_with_sales_tax_in_dollars')} options={currencyFieldOptions} />
      <DateField source='period.end_date' label={label('period_end_date')} options={dateFieldOptions} sortBy='period_end_date' />
    </Datagrid>
  )
}

export default TonnageTicketInvoicesDatagrid
