import { useState, useCallback } from 'react'
import { HttpError } from 'react-admin'
import { getApiUrl } from '../http-client'

const useCompleteTonnageTickets = ({ onFailure, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false)

  const complete = useCallback(async () => {
    let resp
    setIsLoading(true)

    try {
      const url = getApiUrl('/pending_tonnage_tickets/complete_all')
      resp = await fetch(url, { method: 'post', credentials: 'include' })
      const { status, statusText } = resp
      if (status >= 300) throw new HttpError(statusText, status)
      onSuccess(await resp.json())
    } catch (error) {
      onFailure(error)
    }

    setIsLoading(false)
  }, [onFailure, onSuccess])

  return [
    complete,
    isLoading,
  ]
}

export default useCompleteTonnageTickets
