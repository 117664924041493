import {
  TextField,
  DateField,
} from 'react-admin'
import {
  Typography,
} from '@material-ui/core'
import {
  NumberField,
  DatagridWithSubtotals,
} from '../../custom'
import {
  useLabel,
  useTranslateResource,
} from '../../../hooks'
import useHourlyInvoiceTickets, { sortTickets } from './use-hourly-invoice-tickets'
import {
  currencyFieldOptions,
  numberFieldOptions,
  dateFieldOptions,
} from '../../util/component-options'
import { Invoice, DataTable} from '../../shared'
import { useMemo } from 'react'

const TicketsGrid = props => {
  const resource = 'invoice_hourly_tickets'
  const { tickets } = props
  const label = useLabel({ resource })
  const { collection, conditionallyVisibleFields } = useHourlyInvoiceTickets(tickets)

  return (
    <DatagridWithSubtotals data={collection}>
      <DateField source='worked_at_date' label={label('worked_at_date')} options={dateFieldOptions} sortable={false} />
      <TextField source='ticket_number' label={label('ticket_number')} sortable={false} />
      <NumberField source='regular_time_in_hours' label={label('regular_time_in_hours')} options={numberFieldOptions} sortable={false} forceZero />
      <NumberField source='regular_rate_in_dollars' label={label('regular_rate_in_dollars')} options={currencyFieldOptions} sortable={false} />
      { conditionallyVisibleFields.includes('overtime_in_hours') && <NumberField source='overtime_in_hours' label={label('overtime_in_hours')} options={numberFieldOptions} sortable={false} forceZero /> }
      { conditionallyVisibleFields.includes('overtime_rate_in_dollars') && <NumberField source='overtime_rate_in_dollars' label={label('overtime_rate_in_dollars')} options={currencyFieldOptions} sortable={false} /> }
      { conditionallyVisibleFields.includes('doubletime_in_hours') && <NumberField source='doubletime_in_hours' label={label('doubletime_in_hours')} options={numberFieldOptions} sortable={false} forceZero /> }
      { conditionallyVisibleFields.includes('doubletime_rate_in_dollars') && <NumberField source='doubletime_rate_in_dollars' label={label('doubletime_rate_in_dollars')} options={currencyFieldOptions} sortable={false} /> }
      { conditionallyVisibleFields.includes('fees_in_dollars') && <NumberField source='fees_in_dollars' label={label('fees_in_dollars')} options={currencyFieldOptions} sortable={false} /> }
      <TextField source='description' label={''} sortable={false} />
      <NumberField source='total_in_dollars' label={label('total_in_dollars')} options={currencyFieldOptions} sortable={false} forceZero />
    </DatagridWithSubtotals>
  )
}

const Totals = ({ data }) => {
  const translate = useTranslateResource('hourly_ticket_invoices', 'labels.invoice')

  return (
    <DataTable data={data}>
      <NumberField source='tickets_count' label={translate('tickets_count')} />
      <NumberField source='total_in_dollars' label={translate('total_in_dollars')} options={currencyFieldOptions} />
    </DataTable>
  )
}

const HourlyInvoice = ({ record, isPrintView = false }) => {
  const translate = useTranslateResource('hourly_ticket_invoices', 'labels.invoice')

  const {
    customer,
    invoice_number,
    hourly_rate,
    total_in_dollars,
    tickets_count,
    tenant,
    hourly_tickets: tickets,
    period: { end_date: date },
  } = record

  const ticketImages = useMemo(() => {
    return [...tickets].sort(sortTickets).map(ticket => ticket.ticket_image_url).filter(Boolean)
  }, [tickets])

  const Detail = () => (
    <>
      <Typography align='right'>
        {translate('workType')}
      </Typography>
      {
        hourly_rate &&
        <Typography align='right'>
          {hourly_rate.description}
        </Typography>
      }
    </>
  )

  return (
    <Invoice
      customer={customer}
      tenant={tenant}
      invoiceNumber={invoice_number}
      date={date}
      detail={<Detail />}
      ticketImages={ticketImages}
      lineItems={<TicketsGrid tickets={tickets} />}
      totals={<Totals data={{ total_in_dollars, tickets_count }}/>}
      isPrintView={isPrintView}
    />
  )
}

export default HourlyInvoice
