import { useMemo } from 'react'

const useHourlyTicketTimeFields = () => (
  useMemo(() => ([
    {
      source: 'regular_time_in_hours',
      label: 'regular_time_in_hours',
      required: true,
    },
    {
      source: 'overtime_in_hours',
      label: 'overtime_in_hours',
      required: false,
    },
    {
      source: 'doubletime_in_hours',
      label: 'doubletime_in_hours',
      required: false,
    },
    {
      source: 'regular_travel_time_in_hours',
      label: 'regular_travel_time_in_hours',
      required: false,
    },
    {
      source: 'overtime_travel_time_in_hours',
      label: 'overtime_travel_time_in_hours',
      required: false,
    },
    {
      source: 'doubletime_travel_time_in_hours',
      label: 'doubletime_travel_time_in_hours',
      required: false,
    },
  ]),[])
);

export default useHourlyTicketTimeFields
