import {
  Loading,
  NullableBooleanInput,
  TextInput,
} from 'react-admin'
import { useSelector } from 'react-redux'
import Invoice from './invoice'
import Datagrid from './datagrid'
import CollectionData from './collection-data'
import { List, Filter, NumberInput } from '../../custom'
import { PrintListButtons, ToggleUiButton } from '../../shared'
import { withCollectionData } from '../../hoc'
import { useEarliestIncompletePeriod, useLabel } from '../../../hooks'
import PeriodDatePicker from '../../shared/period-date-picker'

const DatagridWithTotals = withCollectionData(Datagrid, CollectionData)

const TonnageTicketInvoicesFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <PeriodDatePicker label={label('period_end_date')} source='period_end_date_eq' />
      <NullableBooleanInput label={label('is_archived')} source='is_archived_true' />
      <TextInput label={label('customer', 'filter')} source='customer_name_i_cont' />
      <NumberInput label={label('tickets_count', 'filter', 'greaterThan')} source='tickets_count_gteq' />
      <NumberInput label={label('total_with_sales_tax_in_dollars', 'filter', 'greaterThan')} source='total_with_sales_tax_in_dollars_gteq' />
    </Filter>
  )
}

const Actions = ({ showDetail }) => {
  return (
    <>
      <PrintListButtons
        summary={<DatagridWithTotals />}
        detail={<Invoice showDetail={showDetail} />}
      />
      <ToggleUiButton uiAttribute='tonnageTicketInvoiceShowDetail' />
    </>
  )
}

const TonnageTicketInvoicesList = props => {
  const period = useEarliestIncompletePeriod()
  const showDetail = useSelector(state => state.ui.tonnageTicketInvoiceShowDetail)

  if (!period) return <Loading />

  return (
    <List
      {...props}
      filters={<TonnageTicketInvoicesFilter />}
      filter={{ include_collection_data: true }}
      actions={<Actions showDetail={showDetail} />}
      filterDefaultValues={{ period_end_date_eq: period?.end_date }}
    >
      <DatagridWithTotals showDetail={showDetail} />
    </List>
  )
}

export default TonnageTicketInvoicesList
