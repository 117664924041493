const conditionallyVisibleFields = {
  overtime_in_hours: [
    'overtime_in_hours',
    'overtime_rate_in_dollars',
  ],
  doubletime_in_hours: [
    'doubletime_in_hours',
    'doubletime_rate_in_dollars',
  ],
  fees_in_dollars: [
    'fees_in_dollars',
  ],
}

export {
  conditionallyVisibleFields,
}
