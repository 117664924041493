import { NumberField, useListContext } from 'react-admin'
import { CollectionDataTable } from '../../shared'
import { currencyFieldOptions } from '../../util/component-options'
import { useLabel } from '../../../hooks'

const CollectionData = props => {
  const context = useListContext()
  const label = useLabel(context)

  return (
    <CollectionDataTable {...props}>
      <NumberField source='tickets_count' label={label('tickets_count')} />
      <NumberField source='total_in_dollars' label={label('total_in_dollars')} options={currencyFieldOptions} />
    </CollectionDataTable>
  )
}

export default CollectionData
