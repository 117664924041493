import {
  TextInput,
  TextField,
  NumberField,
} from 'react-admin'
import { List, Filter, Datagrid, DateInput, NumberInput } from '../../custom'
import { useLabel } from '../../../hooks'
import {
  currencyFieldOptions,
} from '../../util/component-options'

const EmployeeDriverPayRollupsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <DateInput label={label('ticket_date', 'earliest')} source='ticket_date_gteq' />
      <DateInput label={label('ticket_date', 'latest')} source='ticket_date_lteq' />
      <TextInput label={label('last_name', 'filter')} source='last_name_i_cont' />
      <TextInput label={label('first_name', 'filter')} source='first_name_i_cont' />
      <NumberInput label={label('sum_total_line_item_quantity', 'filter', 'greaterThan')} source='sum_total_line_item_quantity_gteq' />
      <NumberInput label={label('sum_total_in_dollars', 'filter', 'greaterThan')} source='sum_total_in_dollars_gteq' />
    </Filter>
  )
}

const EmployeeDriverPayRollupsList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<EmployeeDriverPayRollupsFilter />}
    >
      <Datagrid
        hasEdit={false}
      >
        <TextField source='last_name' label={label('last_name')} />
        <TextField source='first_name' label={label('first_name')} />
        <NumberField source='sum_tickets_regular_total_in_dollars' label={label('sum_tickets_regular_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_tickets_overtime_total_in_dollars' label={label('sum_tickets_overtime_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_tickets_doubletime_total_in_dollars' label={label('sum_tickets_doubletime_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_tickets_total_in_dollars' label={label('sum_tickets_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_minimum_hourly_wage_adjustment_in_dollars' label={label('sum_minimum_hourly_wage_adjustment_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_total_in_dollars' label={label('sum_total_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='sum_total_line_item_quantity' label={label('sum_total_line_item_quantity')} />
      </Datagrid>
    </List>
  )
}

export default EmployeeDriverPayRollupsList
